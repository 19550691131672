import React, { useState } from 'react';
import { Header } from '../../components/header';
import { Tab } from '../../components/tab';
import { AiBasedStyleService } from './components';

import aiHeaderLogo from '../../assets/ai/aiLogo.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';
import AiBasedCoordiService from './components/AiBasedCoordiService';

const DEFAULT_TAB = { tab: 'AI 기반 스타일', key: 'step1' };

const TAB = [
  DEFAULT_TAB,
  {
    tab: 'AI 기반 코디',
    key: 'step2',
  },
];

export const Ai = () => {
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);

  const handleClickTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <Header
        headerIcon={aiHeaderLogo}
        headerTitle="AI 추천"
        rightIcon={commonHeaderLogo}
      />
      <Tab
        tabs={TAB}
        currentTab={currentTab}
        onClickTab={handleClickTab}
        activeColor="#9747FF"
      />
      <RenderBody step={currentTab.key} />
    </div>
  );
};

function RenderBody({ step }) {
  switch (step) {
    case 'step1':
      return <AiBasedStyleService />;
    case 'step2':
      return <AiBasedCoordiService />;
    default:
      return null;
  }
}
