import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { KeywordSearchInput, Container } from '../../../components/common';
import { getFetch, postFetch } from '../../../api';

const TextSearch = () => {
  const [keyword, setKeyword] = useState('');
  const [images, setImages] = useState([]);

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleClickCreate = async (e) => {
    e.preventDefault();

    if (keyword === '') {
      alert('키워드를 입력해주세요.');
      return;
    }

    const response = await getFetch(`ml/similarity/text2image?text=${keyword}`);
    console.log('response', response);
    setImages(response ?? []);
  };

  return (
    <Container>
      <KeywordSearchInput
        keyword={keyword}
        onChangeText={handleChangeKeyword}
      />

      <Col style={{ marginTop: 20 }}>
        <Col
          style={{
            width: '100%',
            backgroundColor: '#f9f9f9',
            borderRadius: 16,
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 40,
            paddingRight: 24,
          }}>
          <Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Col
              style={{
                borderRadius: 8,
                border: '1px solid #FF00C7',
                backgroundColor: '#FF00C7',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 240,
                height: 64,
                cursor: 'pointer',
              }}
              onClick={handleClickCreate}>
              <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
                검색하기
              </span>
            </Col>
          </Row>
        </Col>
      </Col>

      {images.length !== 0 && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
              marginTop: 80,
            }}
          />

          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                검색 이미지
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 16,
              }}>
              {images.map((image, index) => {
                return (
                  <div key={index} style={{ width: 200, height: 200 }}>
                    <img
                      src={image.imgUrl}
                      alt={`Image ${index}`}
                      style={{
                        height: '100%',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Container>
  );
};

export default TextSearch;
