import React, { useEffect, useState } from 'react';
import { Layout, Space, Col, Row, Table, Pagination } from 'antd';
import { Column } from '@ant-design/plots';

import logoImage from '../../assets/dashboard/productIcon.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';

import productHeader from '../../assets/product/banner.svg';
import ImageSearchDatePicker from '../design/components/ImageSearchDatePicker';
import searchIcon from '../../assets/design/searchIcon.svg';
import { getFetch } from '../../api';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import ProductImageSearchCategory from './components/ProductImageSearchCategory';

const { Header, Footer, Sider, Content } = Layout;

const titleStyle = {
  color: '#333',
  fontSize: 18,
  fontWeight: '700',
  marginRight: 24,
};

const boxStyle = {
  height: 48,
  paddingLeft: 24,
  paddingRight: 24,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: 8,
  cursor: 'pointer',
};

const deActiveStyle = {
  border: '1px solid #DDD',
  backgroundColor: '#f9f9f9',
};

const activeStyle = {
  border: '1px solid #000',
  backgroundColor: '#333',
};

const deActiveTextStyle = {
  color: '#999',
  fontSize: 18,
  fontWeight: '700',
};
const activeTextStyle = {
  color: '#fff',
  fontSize: 18,
  fontWeight: '700',
};

const SHOP_LIST = ['musinsa', 'wconcept', 'qoo10', 'rakuten', 'VVIC'];
const PERIOD_LIST = ['weeks', '1months', '3months'];

export const Product = () => {
  const [selectedBrand, setSelectedBrand] = useState('musinsa');
  const [selectedPeriod, setSelectedPeriod] = useState({
    period: '1week',
    startDate: '',
    endDate: '',
  });
  const [top10BrandData, setTop10BrandData] = useState([]);
  const [top10PriceData, setTop10PriceData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productData2, setProductData2] = useState([]);

  const [shop, setShop] = useState('');
  const [selectPeriod, setSelectPeriod] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [firstCategories, setFirstCategories] = useState([]);
  const [secondCategories, setSecondCategories] = useState([]);
  const [thirdCategories, setThirdCategories] = useState([]);

  const [firstCategory, setFirstCategory] = useState(null);
  const [secondCategory, setSecondCategory] = useState(null);
  const [thirdCategory, setThirdCategory] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    if (shop !== '') {
      fetchFirstCategory();
      onResetPeriod();
      onResetCategory();
    }
  }, [shop]);

  useEffect(() => {
    if (firstCategory !== null) {
      fetchSecondCategory();
    }
  }, [firstCategory]);

  useEffect(() => {
    if (secondCategory !== null) {
      fetchThirdCategory();
    }
  }, [secondCategory]);

  const top10PriceConfig = {
    data: top10PriceData,
    xField: 'price',
    yField: 'itemCount',
    seriesField: '',
    color: '#4959E4',
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  const top10BrandConfig = {
    data: top10BrandData,
    xField: 'brand',
    yField: 'itemCount',
    seriesField: '',
    color: '#19AE55',
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  const getTop10BrandData = async () => {
    const url =
      selectedCategoryId === null
        ? `https://cway.api.araas.ai/competitor/${shop}/brands?topk=10&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}`
        : `https://cway.api.araas.ai/competitor/${shop}/brands?categoryId=${selectedCategoryId}&topk=10&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}`;
    console.log('brand 1> ', url);

    let res = await fetch(
      url,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).then((response) => response.json());
    return res;
  };

  const getTop10PriceData = async () => {
    const url =
      selectedCategoryId === null
        ? `https://cway.api.araas.ai/competitor/${shop}/prices?topk=10&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}`
        : `https://cway.api.araas.ai/competitor/${shop}/prices?categoryId=${selectedCategoryId}&topk=10&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}`;
    console.log('price 1> ', url);
    let res = await fetch(
      url,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).then((response) => response.json());
    return res;
  };

  const getProductDataList = async () => {
    setProductData([]);
    const url =
      selectedCategoryId === null
        ? `https://cway.api.araas.ai/competitor/${shop}/items?startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}&page=1&pageSize=50`
        : `https://cway.api.araas.ai/competitor/${shop}/items?categoryId=${selectedCategoryId}&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}&page=1&pageSize=50`;
    console.log('datalist 1> ', url);
    await fetch(
      url,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    )
      .then((response) => response.json())
      .then((data) => {
        const formattedDataList = [];
        data.data.map((d) => {
          formattedDataList.push({
            name: `${d.rank < 10 ? `0${d.rank}` : `${d.rank}`} ${d.name}`,
            brand: `${d.image}~${d.brand}`,
            exposureScore: d.exposureScore,
            top10Entries: `${d.top10Entries}~${d.top10EntrieFdate}`,
            totalEntries: `${d.totalEntries}~${d.totalEntrieFdate}`,
            price: `${d.price}~${d.originPrice}`,
            meanPrice: d.meanPrice,
            gradeScore: d.gradeScore,
            reviewCount: d.reviewCount,
            likeCount: d.likeCount,
          });
        });
        setProductData(formattedDataList);
      });
  };

  const getProductDataList2 = async () => {
    setProductData([]);
    const url =
      selectedCategoryId === null
        ? `https://cway.api.araas.ai/competitor/${shop}/items?startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}&page=2&pageSize=50`
        : `https://cway.api.araas.ai/competitor/${shop}/items?categoryId=${selectedCategoryId}&startDate=${moment(
            startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(endDate).format(
            'YYYY-MM-DD',
          )}&page=2&pageSize=50`;
    console.log('datalist 2> ', url);
    await fetch(
      url,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    )
      .then((response) => response.json())
      .then((data) => {
        const formattedDataList = [];
        data.data.map((d) => {
          formattedDataList.push({
            name: `${d.rank < 10 ? `0${d.rank}` : `${d.rank}`} ${d.name}`,
            brand: `${d.image}~${d.brand}`,
            exposureScore: d.exposureScore,
            top10Entries: `${d.top10Entries}~${d.top10EntrieFdate}`,
            totalEntries: `${d.totalEntries}~${d.totalEntrieFdate}`,
            price: `${d.price}~${d.originPrice}`,
            meanPrice: d.meanPrice,
            gradeScore: d.gradeScore,
            reviewCount: d.reviewCount,
            likeCount: d.likeCount,
          });
        });
        setProductData(formattedDataList);
      });
  };

  const koreanColumns = [
    {
      title: '상품명',
      dataIndex: 'name',
      key: 'name',
      render: (text) => {
        return <text style={{ fontWeight: 'bold' }}>{text}</text>;
      },
    },
    {
      title: '브랜드',
      dataIndex: 'brand',
      key: 'brand',
      render: (text) => {
        const split = text.split('~');
        return (
          <Row>
            <Col style={{ paddingRight: 10 }}>
              <img style={{ width: 60, height: 60 }} src={split[0]} />
            </Col>
            <Col>
              <Row style={{ alignContent: 'center' }}>{split[1]}</Row>
            </Col>
          </Row>
        );
      },
    },
    {
      title: '노출지수',
      dataIndex: 'exposureScore',
      key: 'exposureScore',
    },
    {
      title: '10위 진입수',
      dataIndex: 'top10Entries',
      key: 'top10Entries',
      render: (text) => {
        const split = text.split('~');
        return (
          <Space direction="vertical">
            <Row>{split[0]}</Row>
            <Row>
              <text style={{ color: '#999999' }}>{split[1]}</text>
            </Row>
          </Space>
        );
      },
    },
    {
      title: '전체 진입수',
      dataIndex: 'totalEntries',
      key: 'totalEntries',
      render: (text) => {
        const split = text.split('~');
        return (
          <Space direction="vertical">
            <Row>{split[0]}</Row>
            <Row>
              <text style={{ color: '#999999' }}>{split[1]}</text>
            </Row>
          </Space>
        );
      },
    },
    {
      title: '현재가',
      dataIndex: 'price',
      key: 'price',
      render: (text) => {
        const split = text.split('~');
        return (
          <Space direction="vertical">
            <Row>{split[0]}</Row>
            <Row>
              <text style={{ color: '#999999' }}>{split[1]}</text>
            </Row>
          </Space>
        );
      },
    },
    {
      title: '평균가',
      dataIndex: 'meanPrice',
      key: 'meanPrice',
    },
    {
      title: '평점',
      dataIndex: 'gradeScore',
      key: 'gradeScore',
    },
    {
      title: '리뷰',
      dataIndex: 'reviewCount',
      key: 'reviewCount',
    },
    {
      title: '좋아요',
      dataIndex: 'likeCount',
      key: 'likeCount',
    },
  ];

  const handleClickShop = (shopName) => {
    if (shop === shopName) {
      setShop('');
      return;
    }

    setShop(shopName);
  };

  const fetchFirstCategory = async () => {
    const response = await getFetch(`competitor/${shop}/categoris`);

    setFirstCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };

  const fetchSecondCategory = async () => {
    const response = await getFetch(
      `competitor/${shop}/categoris?parentId=${firstCategory.key}`,
    );

    setSecondCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };
  const fetchThirdCategory = async () => {
    const response = await getFetch(
      `competitor/${shop}/categoris?parentId=${secondCategory.key}`,
    );

    setThirdCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };

  const handleClickStartRange = (range) => {
    setStartDate(range);
  };

  const handleClickEndRange = (range) => {
    setEndDate(range);
  };

  const onResetCategory = () => {
    setFirstCategory(null);
    setSecondCategory(null);
    setThirdCategory(null);
  };

  const onResetPeriod = () => {
    setSelectPeriod('');
    setStartDate(null);
    setEndDate(null);
  };

  const handleLastWeekClick = () => {
    if (selectPeriod === 'weeks') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(1, 'weeks');
    setSelectPeriod('weeks');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleLastMonthClick = () => {
    if (selectPeriod === '1months') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(1, 'months');
    setSelectPeriod('1months');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleLastThreeMonthsClick = () => {
    if (selectPeriod === '3months') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(3, 'months');
    setSelectPeriod('3months');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFirstClick = (key) => {
    if (key === firstCategory?.key) {
      setFirstCategory(null);
      return;
    }

    setFirstCategory(
      firstCategories.find((item) => item.key === key) || '메뉴 선택',
    );
    console.log(
      '1selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
    latestCategoryValue(key);
    console.log(
      '1selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
    setSecondCategories([]);
    setThirdCategories([]);
  };

  const handleSecondClick = (key) => {
    if (key === secondCategory?.key) {
      setSecondCategory(null);
      return;
    }
    setSecondCategory(
      secondCategories.find((item) => item.key === key) || '메뉴 선택',
    );
    console.log(
      '2selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
    latestCategoryValue(key);
    console.log(
      '2selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
    setThirdCategories([]);
  };

  const handleThirdClick = (key) => {
    if (key === thirdCategory?.key) {
      setThirdCategory(null);
      return;
    }
    setThirdCategory(
      thirdCategories.find((item) => item.key === key) || '메뉴 선택',
    );
    console.log(
      '3selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
    latestCategoryValue(key);
    console.log(
      '3selectedCategoryId >>>>>>>>>>>>>>>>>>>>>>>>>>                 ',
      selectedCategoryId,
    );
  };

  const latestCategoryValue = (value) => {
    setSelectedCategoryId(value);
  };

  const handleClickCreateButton = (e) => {
    // e.preventDefault();

    // if (isLoading) {
    //   return;
    // }

    if (shop === '') {
      alert('쇼핑몰을 선택해주세요.');
      return;
    }

    // if (
    //   firstCategory === null ||
    //   secondCategory === null ||
    //   thirdCategory === null
    // ) {
    //   alert('카테고리를 선택해주세요.');
    //   return;
    // }

    if (startDate === null || endDate === null) {
      alert('날짜를 선택해주세요.');
      return;
    }

    // onCreateImage(
    //   shop,
    //   firstCategory?.key,
    //   secondCategory?.key,
    //   thirdCategory?.key,
    //   moment(startDate).format('YYYY-MM-DD'),
    //   moment(endDate).format('YYYY-MM-DD'),
    // );
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Header
          style={{
            textAlign: 'center',
            color: '#fff',
            height: 282,
            backgroundColor: '#FFFFFF',
          }}>
          <Row
            style={{
              width: '100%',
              maxWidth: 1920,
              paddingRight: 24,
              paddingLeft: 24,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Row>
              <Col
                style={{
                  marginRight: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <img
                  src={logoImage}
                  alt="headerLogoImage"
                  style={{ width: 48, height: 48 }}
                />
              </Col>
              <Col>
                <text
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 24,
                    color: '#000',
                  }}>
                  Product 분석
                </text>
              </Col>
            </Row>
            <Col
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
              <Link to="/">
                <img
                  src={commonHeaderLogo}
                  alt="headerRightLogoImage"
                  style={{ alignContent: 'bottom', paddingTop: 8 }}
                />
              </Link>
            </Col>
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              src={productHeader}
              alt="fashionHeader"
              style={{ alignContent: 'center' }}
            />
          </Row>
        </Header>
        <div
          style={{
            paddingTop: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Space
            direction="vertical"
            style={{
              gap: 0,
              display: 'flex',
              backgroundColor: '#FFF',
              width: '100%',
              maxWidth: 1280,
            }}>
            <Col
              style={{
                width: '100%',
                height: 322,
                border: '1px solid #333',
                borderRadius: 16,
                marginBottom: 80,
                overflow: 'hidden',
              }}>
              {/** */}
              <Row
                style={{
                  alignItems: 'center',
                  height: 80,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderBottom: '2px solid #ddd',
                }}>
                <span style={titleStyle}>한국</span>

                <div
                  style={{
                    ...boxStyle,
                    ...(shop === SHOP_LIST[0] ? activeStyle : deActiveStyle),
                    marginRight: 8,
                  }}
                  onClick={() => handleClickShop(SHOP_LIST[0])}>
                  <span
                    style={{
                      ...(shop === SHOP_LIST[0]
                        ? activeTextStyle
                        : deActiveTextStyle),
                    }}>
                    무신사
                  </span>
                </div>

                <div
                  style={{
                    ...boxStyle,
                    ...(shop === SHOP_LIST[1] ? activeStyle : deActiveStyle),
                    marginRight: 24,
                  }}
                  onClick={() => handleClickShop(SHOP_LIST[1])}>
                  <span
                    style={{
                      ...(shop === SHOP_LIST[1]
                        ? activeTextStyle
                        : deActiveTextStyle),
                    }}>
                    W컨셉
                  </span>
                </div>

                <div
                  style={{
                    width: 2,
                    height: 20,
                    marginRight: 24,
                    backgroundColor: '#d9d9d9',
                  }}
                />

                <span style={titleStyle}>일본</span>

                <div
                  style={{
                    ...boxStyle,
                    ...(shop === SHOP_LIST[2] ? activeStyle : deActiveStyle),
                    marginRight: 8,
                  }}
                  onClick={() => handleClickShop(SHOP_LIST[2])}>
                  <span
                    style={{
                      ...(shop === SHOP_LIST[2]
                        ? activeTextStyle
                        : deActiveTextStyle),
                    }}>
                    큐텐
                  </span>
                </div>

                <div
                  style={{
                    ...boxStyle,
                    ...(shop === SHOP_LIST[3] ? activeStyle : deActiveStyle),
                    marginRight: 24,
                  }}
                  onClick={() => handleClickShop(SHOP_LIST[3])}>
                  <span
                    style={{
                      ...(shop === SHOP_LIST[3]
                        ? activeTextStyle
                        : deActiveTextStyle),
                    }}>
                    라쿠텐
                  </span>
                </div>

                <div
                  style={{
                    width: 2,
                    height: 20,
                    marginRight: 24,
                    backgroundColor: '#d9d9d9',
                  }}
                />

                <span style={titleStyle}>중국</span>

                <div
                  style={{
                    ...boxStyle,
                    ...(shop === SHOP_LIST[4] ? activeStyle : deActiveStyle),
                  }}
                  onClick={() => handleClickShop(SHOP_LIST[4])}>
                  <span
                    style={{
                      ...(shop === SHOP_LIST[4]
                        ? activeTextStyle
                        : deActiveTextStyle),
                    }}>
                    VVIC
                  </span>
                </div>
              </Row>

              {/** */}
              <Col
                style={{
                  height: 144,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                  alignItems: 'center',
                }}>
                <Row
                  style={{ justifyContent: 'space-between', marginBottom: 16 }}>
                  <ImageSearchDatePicker
                    selectPeriod={selectPeriod}
                    handleLastWeekClick={handleLastWeekClick}
                    handleLastMonthClick={handleLastMonthClick}
                    handleLastThreeMonthsClick={handleLastThreeMonthsClick}
                    startDate={startDate}
                    endDate={endDate}
                    handleClickStartRange={handleClickStartRange}
                    handleClickEndRange={handleClickEndRange}
                    periodList={PERIOD_LIST}
                  />
                </Row>

                <ProductImageSearchCategory
                  firstCategories={firstCategories}
                  secondCategories={secondCategories}
                  thirdCategories={thirdCategories}
                  firstCategory={firstCategory}
                  secondCategory={secondCategory}
                  thirdCategory={thirdCategory}
                  setSelectedCategoryId={setSelectedCategoryId}
                  handleFirstClick={handleFirstClick}
                  handleSecondClick={handleSecondClick}
                  handleThirdClick={handleThirdClick}
                  latestCategoryValue={latestCategoryValue}
                />
              </Col>

              <Row
                style={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: 96,
                  backgroundColor: '#f5f5f5',
                  paddingLeft: 24,
                  paddingRight: 24,
                }}>
                <Row
                  style={{
                    borderRadius: 8,
                    border: '1px solid #000',
                    backgroundColor: '#000',
                    justifyContent: 'justify-content',
                    alignItems: 'center',
                    height: 64,
                    cursor: 'pointer',
                    paddingLeft: 24,
                    paddingRight: 24,
                    gap: 10,
                  }}
                  onClick={() => {
                    handleClickCreateButton();
                    getTop10BrandData().then((i) => setTop10BrandData(i));
                    getTop10PriceData().then((i) => setTop10PriceData(i));
                    getProductDataList();
                  }}>
                  <img
                    src={searchIcon}
                    alt="image"
                    style={{ height: 32, width: 32 }}
                  />

                  <span
                    style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
                    검색하기
                  </span>
                </Row>
              </Row>
            </Col>
            <Row>
              <text
                style={{ fontSize: 24, fontWeight: 'bold', paddingBottom: 20 }}>
                TOP 10 브랜드
              </text>
            </Row>
            <Row>
              <Column
                style={{ width: '100%', paddingBottom: 20 }}
                {...top10BrandConfig}
              />
            </Row>
            <Row>
              <text
                style={{ fontSize: 24, fontWeight: 'bold', paddingBottom: 20 }}>
                가격대별 상품수
              </text>
            </Row>
            <Row>
              <Column
                style={{ width: '100%', paddingBottom: 20 }}
                {...top10PriceConfig}
              />
            </Row>
            <Row>
              <text
                style={{ fontSize: 24, fontWeight: 'bold', paddingBottom: 20 }}>
                상품순위
              </text>
            </Row>
            <Row>
              <Table
                pagination={{
                  pageSize: 50,
                  defaultPageSize: 50,
                  total: 100,
                  onChange: (page, pageSize) => {
                    if (page === 1) getProductDataList();
                    if (page === 2) getProductDataList2();
                  },
                }}
                style={{ width: '100%', paddingBottom: 20 }}
                columns={koreanColumns}
                dataSource={productData}
              />
            </Row>
          </Space>
        </div>
      </Space>
    </div>
  );
};
