import React from 'react';
import { Col, Row } from 'antd';

const KeywordSearchInput = ({ keyword, onChangeText }) => {
  return (
    <Col
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div style={{ marginBottom: 40 }}>
        <span style={{ fontSize: 24, fontWeight: '800' }}>
          텍스트 기반 유사상품 검색
        </span>
      </div>

      <Col
        style={{
          width: '100%',
          height: 128,
          backgroundColor: '#f9f9f9',
          borderRadius: 16,
          paddingLeft: 40,
          paddingRight: 40,
          display: 'flex',
          alignItems: 'center',
        }}>
        <Row
          style={{
            alignItems: 'center',
            gap: 40,
          }}>
          <div>
            <span
              style={{
                fontSize: 24,
                fontWeight: '700',
              }}>
              키워드
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              width: 903,
              height: 48,
              border: '1px solid #999',
              borderRadius: 8,
              backgroundColor: '#fff',
              overflow: 'hidden',
            }}>
            <input
              value={keyword}
              onChange={onChangeText}
              style={{
                outline: 'none',
                width: '100%',
                fontSize: 18,
                fontWeight: '700',
                border: 'none',
                paddingLeft: 16,
                paddingRight: 8,
              }}
            />
          </div>

          <div>
            <span
              style={{
                fontSize: 12,
                fontWeight: '700',
                color: '#666',
              }}>
              512px 이미지로 생성됩니다
            </span>
          </div>
        </Row>
      </Col>
    </Col>
  );
};

export default KeywordSearchInput;
