import React from 'react';
import { Col, Row } from 'antd';

const ImageSearchResult = ({ uploadImageSrc, onCreateImage, images }) => {
  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <img
          src={uploadImageSrc}
          alt="uploadImageSrc"
          style={{ height: '100%' }}
        />
      </Col>

      <Col
        style={{
          width: '100%',
          backgroundColor: '#f9f9f9',
          borderRadius: 16,
          paddingTop: 24,
          paddingBottom: 24,
          paddingLeft: 40,
          paddingRight: 24,
          marginBottom: 80,
        }}>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Col>
            <Col>
              <span style={{ fontSize: 18, fontWeight: '700' }}>
                특정 이미지를 기반으로 유사 상품을 검색합니다.
              </span>
            </Col>
          </Col>

          <Col
            style={{
              borderRadius: 8,
              border: '1px solid #FF00C7',
              backgroundColor: '#FF00C7',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 240,
              height: 64,
              cursor: 'pointer',
            }}
            onClick={onCreateImage}>
            <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
              검색하기
            </span>
          </Col>
        </Row>
      </Col>

      {images.length !== 0 && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
            }}
          />
          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                생성 이미지
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 16,
              }}>
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: 200,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img
                      src={image.imgUrl}
                      alt={`Image ${index}`}
                      style={{
                        height: '100%',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Col>
  );
};

export default ImageSearchResult;
