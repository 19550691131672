import React from 'react';
import { Col, Row, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

const ImageCreateResultDown = ({ uploadImageSrc, onCreateImage, images }) => {
  const handleClick = (url) => {
    saveAs(url, 'image.webp');
  };

  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <img
          src={uploadImageSrc}
          alt="uploadImageSrc"
          style={{ height: '100%' }}
        />
      </Col>

      <Col
        style={{
          width: '100%',
          backgroundColor: '#f9f9f9',
          borderRadius: 16,
          paddingTop: 24,
          paddingBottom: 24,
          paddingLeft: 40,
          paddingRight: 24,
          marginBottom: 80,
        }}>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Col>
            <Col></Col>
          </Col>
          <Col
            style={{
              borderRadius: 8,
              border: '1px solid #9747FF',
              backgroundColor: '#9747FF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 240,
              height: 64,
              cursor: 'pointer',
            }}
            onClick={onCreateImage}>
            <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
              생성하기
            </span>
          </Col>
        </Row>
      </Col>

      {images.length !== 0 && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
            }}
          />
          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                Style 적용 이미지
              </span>
              <Button
                onClick={() => handleClick(images[0])}
                shape="round"
                icon={<DownloadOutlined />}
                style={{ float: 'right' }}>
                이미지 다운로드
              </Button>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 16,
              }}>
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: 1280,
                      height: 1928,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#D9D9D9',
                    }}>
                    <img
                      src={image.image}
                      alt={`Image ${index}`}
                      style={{
                        height: image.height,
                        width: image.width,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Col>
  );
};

export default ImageCreateResultDown;
