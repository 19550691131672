import React from 'react';
import { Col, Row, Dropdown, Menu } from 'antd';
import bottomArrow from '../../../assets/design/bottom_arrow.svg';

const ImageSearchCategory = ({
  firstCategory,
  secondCategory,
  thirdCategory,
  firstCategories,
  secondCategories,
  thirdCategories,
  handleFirstClick,
  handleSecondClick,
  handleThirdClick,
}) => {
  const firstCategoryMenu = (
    <Menu>
      {firstCategories.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleFirstClick(item.key)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const secondCategoryMenu = (
    <Menu>
      {secondCategories.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleSecondClick(item.key)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const thirdCategoryMenu = (
    <Menu>
      {thirdCategories.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleThirdClick(item.key)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Row style={{ gap: 8 }}>
      <Col style={{ flex: 1 }}>
        <Dropdown overlay={firstCategoryMenu}>
          <div
            onClick={(e) => e.preventDefault()}
            style={{
              flex: 1,
              height: 48,
              paddingLeft: 16,
              paddingRight: 8,
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid #DDD',
              backgroundColor: '#fff',
            }}>
            <span style={{ fontSize: 18, fontWeight: '700' }}>
              {firstCategory === null ? '카테고리 선택' : firstCategory.label}
            </span>

            <img
              src={bottomArrow}
              alt="image"
              style={{ height: 32, width: 32 }}
            />
          </div>
        </Dropdown>
      </Col>

      <Col style={{ flex: 1 }}>
        <Dropdown overlay={secondCategoryMenu}>
          <div
            onClick={(e) => e.preventDefault()}
            style={{
              flex: 1,
              height: 48,
              paddingLeft: 16,
              paddingRight: 8,
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid #DDD',
              backgroundColor: '#fff',
            }}>
            <span style={{ fontSize: 18, fontWeight: '700' }}>
              {secondCategory === null ? '카테고리 선택' : secondCategory.label}
            </span>

            <img
              src={bottomArrow}
              alt="image"
              style={{ height: 32, width: 32 }}
            />
          </div>
        </Dropdown>
      </Col>

      <Col style={{ flex: 1 }}>
        <Dropdown overlay={thirdCategoryMenu}>
          <div
            onClick={(e) => e.preventDefault()}
            style={{
              flex: 1,
              height: 48,
              paddingLeft: 16,
              paddingRight: 8,
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid #DDD',
              backgroundColor: '#fff',
            }}>
            <span style={{ fontSize: 18, fontWeight: '700' }}>
              {thirdCategory === null ? '카테고리 선택' : thirdCategory.label}
            </span>

            <img
              src={bottomArrow}
              alt="image"
              style={{ height: 32, width: 32 }}
            />
          </div>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default ImageSearchCategory;
