import React, { useState, useEffect } from 'react';
import { Card, Space, Row, Col } from 'antd';
import Icon, { UserOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Dropdown } from 'antd';

import sustainly_rising from '../../assets/fashion/ranking/sustainlyRising.svg';
import sustainly_decline from '../../assets/fashion/ranking/sustainlyDecline.svg';
import sustainly_stay from '../../assets/fashion/ranking/sustainlyStay.svg';
import seasonaly_rising from '../../assets/fashion/ranking/seasonalyRising.svg';
import seasonaly_decline from '../../assets/fashion/ranking/seasonalyDecline.svg';
import seasonaly_stay from '../../assets/fashion/ranking/seasonalyStay.svg';
import star_rising from '../../assets/fashion/ranking/starRising.svg';
import star_decline from '../../assets/fashion/ranking/starDecline.svg';
import unclassified from '../../assets/fashion/ranking/unclassified.svg';

export const BaiduTrendSearching = () => {
  const [trendSearchingFront, setTrendSearchingFront] = useState([]);
  const [trendSearchingRear, setTrendSearchingRear] = useState([]);
  const [tempTrendSearching, setTempTrendSearching] = useState([]);
  const [selectedType, setSelectedType] = useState({
    key: 'ALL',
    label: 'ALL',
    icon: <UserOutlined />,
  });
  const [updateDate, setUpdateDate] = useState();

  useEffect(() => {
    const getUpdateDate = async () => {
      const getRes = await fetch(
        'https://cway.api.araas.ai/keywords/updatedAt?platform=BAIDU',
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return getRes;
    };
    getUpdateDate().then((res) => setUpdateDate(res));

    const fetchData = async () => {
      const res = await fetch(
        `https://cway.api.araas.ai/keywords/classifications?platform=BAIDU&classType=sustainly_rising&channel=ALL`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return res;
    };
    fetchData().then((res) => {
      setTempTrendSearching(res);
      setTrendSearchingFront(res.slice(0, res.length / 2 - 1));
      setTrendSearchingRear(res.slice(res.length / 2, res.length - 1));
    });
  }, []);

  const filterIcon = (type) => {
    if (type === 'sustainly_rising')
      return (
        <img
          src={sustainly_rising}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'sustainly_decline')
      return (
        <img
          src={sustainly_decline}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'sustainly_stay')
      return (
        <img
          src={sustainly_stay}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'seasonaly_rising')
      return (
        <img
          src={seasonaly_rising}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'seasonaly_decline')
      return (
        <img
          src={seasonaly_decline}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'seasonaly_stay')
      return (
        <img
          src={seasonaly_stay}
          alt="image"
          style={{ height: 20, width: 20 }}
        />
      );
    if (type === 'star_rising')
      return (
        <img src={star_rising} alt="image" style={{ height: 20, width: 20 }} />
      );
    if (type === 'star_decline')
      return (
        <img src={star_decline} alt="image" style={{ height: 20, width: 20 }} />
      );
    if (type === 'unclassified')
      return (
        <img src={unclassified} alt="image" style={{ height: 20, width: 20 }} />
      );
  };

  const handleMenuClick = (e) => {
    items.forEach((i) => {
      if (i.key === e.key) {
        setSelectedType(i);
        const newList = tempTrendSearching.slice(
          0,
          tempTrendSearching.length - 1,
        );

        const filteredList = [];
        newList.forEach((i) => {
          if (i.total === e.key) filteredList.push(i);
        });

        setTrendSearchingFront(
          filteredList.slice(0, filteredList.length / 2 - 1),
        );
        setTrendSearchingRear(
          filteredList.slice(filteredList.length / 2, filteredList.length - 1),
        );
        return;
      }
    });
  };

  const items = [
    {
      label: '지속 상승',
      key: 'sustainly_rising',
      icon: <Icon component={() => filterIcon('sustainly_rising')} />,
    },
    {
      label: '지속 하강',
      key: 'sustainly_decline',
      icon: <Icon component={() => filterIcon('sustainly_decline')} />,
    },
    {
      label: '지속 유지',
      key: 'sustainly_stay',
      icon: <Icon component={() => filterIcon('sustainly_stay')} />,
    },
    {
      label: '주기 상승',
      key: 'seasonaly_rising',
      icon: <Icon component={() => filterIcon('seasonaly_rising')} />,
    },
    {
      label: '주기 하락',
      key: 'seasonaly_decline',
      icon: <Icon component={() => filterIcon('seasonaly_decline')} />,
    },
    {
      label: '주기 유지',
      key: 'seasonaly_stay',
      icon: <Icon component={() => filterIcon('seasonaly_stay')} />,
    },
    {
      label: '급속 상승',
      key: 'star_rising',
      icon: <Icon component={() => filterIcon('star_rising')} />,
    },
    {
      label: '급속 하락',
      key: 'star_decline',
      icon: <Icon component={() => filterIcon('star_decline')} />,
    },
    {
      label: '미분류',
      key: 'unclassified',
      icon: <Icon component={() => filterIcon('unclassified')} />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Space
        direction="vertical"
        size={16}
        style={{
          display: 'flex',
          backgroundColor: '#FFF',
        }}>
        <Row>
          <Col style={{ width: 1100 }}>
            <Row
              style={{
                paddingLeft: 100,
                paddingBottom: 30,
                fontSize: 24,
                height: 24,
              }}>
              <text>바이두 패션 키워드 트렌드 검색량 분석</text>
            </Row>
            <Row
              style={{
                paddingLeft: 100,
                paddingBottom: 30,
                fontSize: 18,
                height: 18,
                color: '#666666',
              }}>
              <text>
                트랜드 검색량 추이를 5년을 기준으로 8가지 분류로 확인 할 수
                있습니다.
              </text>
            </Row>
            <Row
              style={{
                paddingLeft: 100,
                paddingBottom: 0,
                fontSize: 18,
                height: 18,
                color: '#999999',
              }}>
              <text>
                업데이트 날짜: {updateDate} (매월 1일에 업데이트됩니다.)
              </text>
            </Row>
          </Col>
          <Col style={{ paddingTop: 30 }}>
            <Dropdown.Button
              menu={menuProps}
              style={{ blockSize: 50 }}
              placement="bottom">
              {selectedType.icon}
              {selectedType.label}
            </Dropdown.Button>
          </Col>
        </Row>
        <Row style={{ padding: 100 }}>
          <Col style={{ paddingRight: 50, width: 650, lineHeight: 10 }}>
            <Card
              title={
                <span>
                  <Row>
                    <Col
                      style={{ width: 150, fontSize: 18, fontWeight: 'bold' }}>
                      검색어
                    </Col>
                    <Col style={{ width: 330 }} />
                    <Col style={{ fontSize: 18, fontWeight: 'bold' }}>추이</Col>
                  </Row>
                </span>
              }>
              {trendSearchingFront.map((d) => (
                <Row style={{ height: 64 }}>
                  <Col style={{ width: 150, fontSize: 18 }}>{d.name}</Col>
                  <Col style={{ width: 330 }} />
                  <Col style={{ float: 'right' }}>{filterIcon(d.total)}</Col>
                </Row>
              ))}
            </Card>
          </Col>
          <Col style={{ paddingRight: 50, width: 650, lineHeight: 10 }}>
            <Card
              title={
                <span>
                  <Row>
                    <Col
                      style={{ width: 150, fontSize: 18, fontWeight: 'bold' }}>
                      검색어
                    </Col>
                    <Col style={{ width: 330 }} />
                    <Col style={{ fontSize: 18, fontWeight: 'bold' }}>추이</Col>
                  </Row>
                </span>
              }>
              {trendSearchingRear.map((d) => (
                <Row style={{ height: 64 }}>
                  <Col style={{ width: 150, fontSize: 18 }}>{d.name}</Col>
                  <Col style={{ width: 330 }} />
                  <Col style={{ float: 'right' }}>{filterIcon(d.total)}</Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Row>
      </Space>
    </div>
  );
};
