import React, { useState } from 'react';
import { Header } from '../../components/header';
import { Tab } from '../../components/tab';

import { ImageSearch, TextSearch } from './components';

import similarProductLogo from '../../assets/similarProduct/similarProductLogo.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';

const DEFAULT_TAB = { tab: '이미지 기반 유사상품 검색', key: 'step1' };
const TAB = [
  DEFAULT_TAB,
  {
    tab: '텍스트 기반 유사상품 검색',
    key: 'step2',
  },
];

export const SimilarProduct = () => {
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);

  const handleClickTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <Header
        headerIcon={similarProductLogo}
        headerTitle="유사 Product 검색"
        rightIcon={commonHeaderLogo}
      />
      <Tab
        tabs={TAB}
        currentTab={currentTab}
        onClickTab={handleClickTab}
        activeColor="#FF00C7"
      />
      <RenderBody step={currentTab.key} />
    </div>
  );
};

function RenderBody({ step }) {
  switch (step) {
    case 'step1':
      return <ImageSearch />;
    case 'step2':
      return <TextSearch />;
    default:
      return null;
  }
}
