import React from 'react';
import { Col, Layout, Row } from 'antd';
import { DashBoardCard } from './Card';

import logoImage from '../../assets/dashboard/logo.svg';
import fashionTrendIcon from '../../assets/dashboard/fashionTrendIcon.svg';
import fashionTrendImage from '../../assets/dashboard/fastionTrendImage.svg';

import productIcon from '../../assets/dashboard/productIcon.svg';
import productImage from '../../assets/dashboard/productImage.svg';

import designIcon from '../../assets/dashboard/degsinIcon.svg';
import designImage from '../../assets/dashboard/designImage.svg';

import imageIcon from '../../assets/dashboard/iamgeIcon.svg';
import imageImage from '../../assets/dashboard/imageImage.svg';

import similarIcon from '../../assets/dashboard/similarIcon.svg';
import similarImage from '../../assets/dashboard/similarImage.svg';

import aiIcon from '../../assets/dashboard/aiIcon.svg';
import aiImage from '../../assets/dashboard/aiImage.svg';

const { Header, Content } = Layout;
const headerStyle = {
  height: 60,
  backgroundColor: '#333333',
};
const contentStyle = {
  textAlign: 'center',
  height: '100%',
  lineHeight: '120px',
  color: '#FFFFFF',
  backgroundColor: '#333333',
};

export const Dashboard = () => {
  return (
    <div
      className="App"
      style={{
        backgroundColor: '#333333',
        height: '100vh',
      }}>
      <div
        style={{
          backgroundColor: '#333333',
          height: 'flex',
        }}>
        <Layout>
          <Header style={headerStyle}>
            <Row>
              <Col style={{ paddingRight: 5 }}>
                <img
                  src={logoImage}
                  alt="logoImage"
                  style={{ alignContent: 'bottom', paddingTop: 8 }}
                />
              </Col>
              <Col>
                <text
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 23,
                    color: '#FFFFFF',
                  }}>
                  DashBoard
                </text>
              </Col>
            </Row>
          </Header>
          <Content style={contentStyle}>
            <Row gutter={16} align="center">
              <Col className="gutter-row" span={6}>
                <a href="/feshion">
                  <DashBoardCard
                    iconImage={fashionTrendIcon}
                    title="Fashion Trend 분석"
                    content="트렌드 예측 및 비교, 그리고 벡터서치를 활용한 분류 등을 확인 할 수 있습니다."
                    image={fashionTrendImage}
                  />
                </a>
              </Col>
              <Col className="gutter-row" span={6}>
                <a href="/product">
                  <DashBoardCard
                    iconImage={productIcon}
                    title="Product 분석"
                    content="경쟁 브랜드, 상품 등의 정보를 한눈에 파악할 수 있습니다."
                    image={productImage}
                  />
                </a>
              </Col>
              <Col className="gutter-row" span={6}>
                <a href="/design">
                  <DashBoardCard
                    iconImage={designIcon}
                    title="Design 분석"
                    content="이미지, 텍스트 기반으로 새로운 디자인을 생성합니다."
                    image={designImage}
                  />
                </a>
              </Col>
            </Row>
            <Row gutter={16} align="center">
              <Col className="gutter-row" span={6}>
                <a href="/portfolio">
                  <DashBoardCard
                    iconImage={imageIcon}
                    title="이미지 Portfolio"
                    content="이미지 특징을 분석하여 분류하며, White space 등을 확인 할 수 있습니다."
                    image={imageImage}
                  />
                </a>
              </Col>
              <Col className="gutter-row" span={6}>
                <a href="/similar-product">
                  <DashBoardCard
                    iconImage={similarIcon}
                    title="유사 Product 검색"
                    content="특정 이미지, 입력된 텍스트 기반으로 유사 상품을 검색합니다."
                    image={similarImage}
                  />
                </a>
              </Col>
              <Col className="gutter-row" span={6}>
                <a href="/ai">
                  <DashBoardCard
                    iconImage={aiIcon}
                    title="AI 추천"
                    content="특정 이미지에 새로운 style을 입히거나 이미지와 어울리는 상품을 추천합니다."
                    image={aiImage}
                  />
                </a>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    </div>
  );
};
