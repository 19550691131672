import React, { useState } from 'react';
import { Col, Row, Checkbox, Input } from 'antd';

const ImageCordiCreateResultUp = ({
  uploadImageSrc,
  onCreateImage,
  images,
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [keyword, setKeyword] = useState('원피스');
  const plainOptions = [
    'outer',
    'top',
    'bottom',
    'dress',
    'shoes',
    'hat',
    'bag',
  ];

  const onChange = (checkedValues) => {
    setSelectedTypes(checkedValues);
  };

  const inputOnChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <img
          src={uploadImageSrc}
          alt="uploadImageSrc"
          style={{ height: '100%' }}
        />
      </Col>
      <Col
        style={{
          width: '100%',
          backgroundColor: '#f9f9f9',
          borderRadius: 16,
          paddingTop: 24,
          paddingBottom: 24,
          paddingLeft: 40,
          paddingRight: 24,
          marginBottom: 80,
        }}>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Col style={{ width: 850 }}>
            <Row style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 5 }}>
              카테고리
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Checkbox.Group options={plainOptions} onChange={onChange} />
            </Row>
            <Row style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 5 }}>
              키워드
            </Row>
            <Row>
              <Input defaultValue="원피스" onChange={inputOnChange} />
            </Row>
          </Col>
          <Col
            style={{
              borderRadius: 8,
              border: '1px solid #9747FF',
              backgroundColor: '#9747FF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 240,
              height: 64,
              cursor: 'pointer',
            }}
            onClick={() => onCreateImage(selectedTypes, keyword)}>
            <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
              생성하기
            </span>
          </Col>
        </Row>
      </Col>
      {images.length !== 0 && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
            }}
          />
          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                생성 이미지
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 16,
              }}>
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: 200,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#FFF',
                    }}>
                    <img
                      src={image.image}
                      alt={`Image ${index}`}
                      style={{
                        height: '100%',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Col>
  );
};

export default ImageCordiCreateResultUp;
