const BASE_URL = 'https://cway.api.araas.ai/';

export const getFetch = async (url) => {
  const res = await fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  }).then((response) => response.json());
  return res;
};

export const postFetch = async (url, data) => {
  const res = await fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return res;
};

export const postImageFetch = async (url, data) => {
  const res = await fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: data,
  }).then((response) => response.json());
  return res;
};
