import React, { useState, useEffect } from 'react';
import { Card, Space, Row, Col, Button } from 'antd';
import { Line } from '@ant-design/plots';

export const NaverTrendRanking = () => {
  const [isMore, setMore] = useState(false);
  const [data1week, setData1week] = useState([]);
  const [data1month, setData1month] = useState([]);
  const [data3month, setdata3month] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isMoreDetail, setMoreDetail] = useState(false);
  const [detailChartData, setDetailChartData] = useState([{}]);
  const [detailCards, setDetailCards] = useState();
  const [selectedDetail, setSelectedDetail] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [relatedSubject, setRelatedSubject] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [updateDate, setUpdateDate] = useState();

  useEffect(() => {
    const getUpdateDate = async () => {
      const getRes = await fetch(
        'https://cway.api.araas.ai/keywords/updatedAt?platform=NAVER',
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return getRes;
    };
    getUpdateDate().then((res) => setUpdateDate(res));

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;

    const fetch1weekData = async () => {
      const todayBefore1week = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7,
      );
      const formatted1weekDate = `${todayBefore1week.getFullYear()}-${
        todayBefore1week.getMonth() + 1
      }-${todayBefore1week.getDate()}`;
      const res1week = await fetch(
        `https://cway.api.araas.ai/keywords/ranks?platform=NAVER&startDate=${formatted1weekDate}&endDate=${formattedDate}&page=1&pageSize=15`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return res1week.data;
    };
    fetch1weekData().then((res) => setData1week(res));

    const fetch1monthData = async () => {
      const todayBefore1month = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
      );
      const formatted1monthDate = `${todayBefore1month.getFullYear()}-${
        todayBefore1month.getMonth() + 1
      }-${todayBefore1month.getDate()}`;
      const res1month = await fetch(
        `https://cway.api.araas.ai/keywords/ranks?platform=NAVER&startDate=${formatted1monthDate}&endDate=${formattedDate}&page=1&pageSize=15`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return res1month.data;
    };
    fetch1monthData().then((res) => setData1month(res));

    const fetch3monthData = async () => {
      const todayBefore3month = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate(),
      );
      const formatted3monthDate = `${todayBefore3month.getFullYear()}-${
        todayBefore3month.getMonth() + 1
      }-${todayBefore3month.getDate()}`;
      const res3month = await fetch(
        `https://cway.api.araas.ai/keywords/ranks?platform=NAVER&startDate=${formatted3monthDate}&endDate=${formattedDate}&page=1&pageSize=15`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
      ).then((response) => response.json());
      return res3month.data;
    };
    fetch3monthData().then((res) => setdata3month(res));
  }, []);

  const rankingFormatting = (rank) => {
    if (rank < 10) return `0${rank}`;
    return rank;
  };

  const clickMore = () => {
    getChartData('1week').then((res) => {
      setChartData(res);
      setMore(true);
    });
  };

  const config = {
    data: chartData,
    xField: 'time',
    yField: 'value',
    seriesField: 'name',
    color: ['#1979C9', '#D62A0D', '#FAA219'],
  };

  const detailConfig = {
    data: detailChartData,
    xField: 'time',
    yField: 'value',
    seriesField: 'name',
    color: ['#1979C9', '#D62A0D', '#FAA219'],
  };

  const convertingDetailCard = (input) => {
    const output = [];
    input.forEach((i) => {
      output.push({
        rank: i.rank,
        name: i.name,
        totalSearchCount: i.totalSearchCount,
        id: i.id,
      });
    });
    return output;
  };

  const convertingChartData = (input) => {
    const output = [];
    input.forEach((i) => {
      i.trends.forEach((it) => {
        output.push({
          id: i.id,
          name: i.name,
          time: it.date,
          value: it.value,
        });
      });
    });
    return output;
  };

  const convertingDetailChartData = (input) => {
    const output = [];
    input.data.forEach((i) => {
      output.push({
        time: i.date,
        value: i.value,
        name: input.name,
        id: input.id,
      });
    });
    return output;
  };

  const getChartData = async (period) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;
    const beforeDate =
      period === '1week'
        ? new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
        : period === '1month'
        ? new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        : period === '3month'
        ? new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        : null;
    const formattedBeforeDate = `${beforeDate.getFullYear()}-${
      beforeDate.getMonth() + 1
    }-${beforeDate.getDate()}`;

    let res = await fetch(
      `https://cway.api.araas.ai/keywords/ranks/trends?platform=NAVER&topk=20&startDate=${formattedBeforeDate}&endDate=${formattedDate}&timeUnit=date`,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).then((response) => response.json());
    const detailCard = convertingDetailCard(res);
    setDetailCards(detailCard);
    return convertingChartData(res);
  };

  const getDetailChartData = async (period, uuid) => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;
    const beforeDate =
      period === '1week'
        ? new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
        : period === '1month'
        ? new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        : period === '3month'
        ? new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        : period === 'after3month'
        ? new Date(today.getFullYear(), today.getMonth() + 3, today.getDate())
        : period === 'after1year'
        ? new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
        : null;
    const formattedBeforeDate = `${beforeDate.getFullYear()}-${
      beforeDate.getMonth() + 1
    }-${beforeDate.getDate()}`;
    let res = await fetch(
      `https://cway.api.araas.ai/keywords/${uuid}/trends?channel=ALL&startDate=${formattedBeforeDate}&endDate=${formattedDate}&timeUnit=date`,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).then((response) => response.json());
    return convertingDetailChartData(res);
  };

  const getDetailDatas = async (uuid) => {
    let res = await fetch(
      `https://cway.api.araas.ai/keywords/${uuid}/relations?topk=20`,
      new Headers({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ).then((response) => response.json());
    setRelatedSubject(res.subjects);
    setSearchKeywords(res.searchKeywords);
  };

  const onClickDetailMore = (period, item) => {
    setMoreDetail(true);
    setSelectedName(item.name);
    setSelectedDetail(item.id);
    getDetailChartData(period, item.id).then((i) => {
      setDetailChartData(i);
    });
    getDetailDatas(item.id);
  };

  return (
    <>
      {isMore ? (
        <div>
          {isMoreDetail ? (
            <Space
              direction="vertical"
              size={16}
              style={{
                display: 'flex',
                backgroundColor: '#FFF',
              }}>
              <div style={{ paddingLeft: 100, paddingRight: 100, height: 700 }}>
                <Row
                  style={{
                    fontSize: 18,
                    paddingBottom: 20,
                    height: 45,
                    color: '#666666',
                  }}>
                  주제
                </Row>
                <Row
                  style={{
                    fontSize: 24,
                    color: '#000000',
                    paddingBottom: 20,
                    height: 45,
                  }}>
                  {selectedName}
                </Row>
                <Row
                  style={{
                    fontSize: 18,
                    color: '#666666',
                    paddingBottom: 30,
                    height: 45,
                  }}>
                  업데이트 날짜: {updateDate}
                </Row>
                <Row>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getDetailChartData('1week', selectedDetail).then(
                          (res) => {
                            setDetailChartData(res);
                          },
                        );
                      }}>
                      최근 1주일
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getDetailChartData('1month', selectedDetail).then(
                          (res) => {
                            setDetailChartData(res);
                          },
                        );
                      }}>
                      최근 1개월
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getDetailChartData('3month', selectedDetail).then(
                          (res) => {
                            setDetailChartData(res);
                          },
                        );
                      }}>
                      최근 3개월
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getDetailChartData('after3month', selectedDetail).then(
                          (res) => {
                            setDetailChartData(res);
                          },
                        );
                      }}>
                      향후 3개월
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getDetailChartData('after1year', selectedDetail).then(
                          (res) => {
                            setDetailChartData(res);
                          },
                        );
                      }}>
                      향후 1년
                    </Button>
                  </Col>
                  <Col span={7}></Col>
                </Row>
                <Row>
                  <Line
                    {...detailConfig}
                    style={{ width: '100%', height: 602 }}
                  />
                </Row>
                <Row style={{ paddingTop: 40 }}>
                  <Col style={{ width: '50%', paddingRight: 20 }}>
                    <Card style={{ width: '100%' }} title={'관련 주제'}>
                      {relatedSubject.map((i, index) => (
                        <Row style={{ height: 64 }}>
                          <span style={{ fontWeight: '800', fontSize: 16 }}>
                            {index + 1 < 10
                              ? `0${index + 1} ${i}`
                              : `${index + 1} ${i}`}
                          </span>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                  <Col style={{ width: '50%', paddingLeft: 20 }}>
                    <Card style={{ width: '100%' }} title={'관련 검색어'}>
                      {searchKeywords.map((i, index) => (
                        <Row style={{ height: 64 }}>
                          <span style={{ fontWeight: '800', fontSize: 16 }}>
                            {index + 1 < 10
                              ? `0${index + 1} ${i}`
                              : `${index + 1} ${i}`}
                          </span>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                </Row>
              </div>
            </Space>
          ) : (
            <Space
              direction="vertical"
              size={16}
              style={{
                display: 'flex',
                backgroundColor: '#FFF',
              }}>
              <div style={{ paddingLeft: 100, paddingRight: 100, height: 700 }}>
                <Row style={{ fontSize: 24, paddingBottom: 20, height: 45 }}>
                  네이버 패션 키워드 검색 순위
                </Row>
                <Row
                  style={{
                    fontSize: 18,
                    color: '#666666',
                    paddingBottom: 20,
                    height: 45,
                  }}>
                  네이버 검색량 기반 키워드 순위를 확인할 수 있습니다.
                </Row>
                <Row
                  style={{
                    fontSize: 18,
                    color: '#999999',
                    paddingBottom: 30,
                    height: 45,
                  }}>
                  업데이트 날짜: {updateDate}
                </Row>
                <Row>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getChartData('1week').then((res) => {
                          setChartData(res);
                        });
                      }}>
                      최근 1주일
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getChartData('1month').then((res) => {
                          setChartData(res);
                        });
                      }}>
                      최근 1개월
                    </Button>
                  </Col>
                  <Col span={1.4} style={{ paddingRight: 20 }}>
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        getChartData('3month').then((res) => {
                          setChartData(res);
                        });
                      }}>
                      최근 3개월
                    </Button>
                  </Col>
                  <Col span={7}></Col>
                </Row>
                <Row>
                  <Line {...config} style={{ width: '100%', height: 602 }} />
                </Row>
                <Row
                  style={{
                    paddingTop: 50,
                    paddingRight: 100,
                    paddingLeft: 100,
                  }}>
                  <Card
                    style={{ width: '100%' }}
                    title={
                      <span>
                        <Row>
                          <Col
                            style={{
                              fontSize: 18,
                              fontWeight: 'bold',
                              width: '10%',
                            }}>
                            No.
                          </Col>
                          <Col
                            style={{
                              fontSize: 18,
                              fontWeight: 'bold',
                              width: '80%',
                            }}>
                            검색어
                          </Col>
                          <Col
                            style={{
                              fontSize: 18,
                              fontWeight: 'bold',
                              float: 'right',
                            }}>
                            총 검색량
                          </Col>
                        </Row>
                      </span>
                    }>
                    {detailCards.map((i, index) => (
                      <Row
                        onClick={() => onClickDetailMore('1week', i)}
                        style={{ height: 64 }}>
                        <Col style={{ width: '10%', fontSize: 18 }}>
                          {i.rank}
                        </Col>
                        <Col style={{ width: '80%', fontSize: 18 }}>
                          {i.name}
                        </Col>
                        <Col style={{ float: 'right', fontSize: 18 }}>
                          {i.totalSearchCount}
                        </Col>
                      </Row>
                    ))}
                  </Card>
                </Row>
              </div>
            </Space>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Space
            direction="vertical"
            size={16}
            style={{
              display: 'flex',
              backgroundColor: '#FFF',
              height: 400,
            }}>
            <Row>
              <Col style={{ padding: 40 }}>
                <Card
                  title="최근 1주일"
                  extra={<Button onClick={clickMore}>상세 보기</Button>}
                  style={{ width: 400 }}>
                  {data1week.map((d) => (
                    <Row style={{ paddingBottom: 24 }}>
                      <Col
                        style={{
                          paddingRight: 8,
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}>
                        {rankingFormatting(d.rank)}
                      </Col>
                      <Col style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {d.name}
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
              <Col style={{ padding: 40 }}>
                <Card
                  title="최근 1개월"
                  extra={<Button onClick={clickMore}>상세 보기</Button>}
                  style={{ width: 400 }}>
                  {data1month.map((d) => (
                    <Row style={{ paddingBottom: 24 }}>
                      <Col
                        style={{
                          paddingRight: 8,
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}>
                        {rankingFormatting(d.rank)}
                      </Col>
                      <Col style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {d.name}
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
              <Col style={{ padding: 40 }}>
                <Card
                  title="최근 3개월"
                  extra={<Button onClick={clickMore}>상세 보기</Button>}
                  style={{ width: 400 }}>
                  {data3month.map((d) => (
                    <Row style={{ paddingBottom: 24 }}>
                      <Col
                        style={{
                          paddingRight: 8,
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}>
                        {rankingFormatting(d.rank)}
                      </Col>
                      <Col style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {d.name}
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
          </Space>
        </div>
      )}
    </>
  );
};
