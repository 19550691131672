import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { getFetch } from '../../../api';
import searchIcon from '../../../assets/design/searchIcon.svg';
import ImageSearchCategory from './ImageSearchCategory';
import ImageSearchDatePicker from './ImageSearchDatePicker';

const titleStyle = {
  color: '#333',
  fontSize: 18,
  fontWeight: '700',
  marginRight: 24,
};

const boxStyle = {
  height: 48,
  paddingLeft: 24,
  paddingRight: 24,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: 8,
  cursor: 'pointer',
};

const deActiveStyle = {
  border: '1px solid #DDD',
  backgroundColor: '#f9f9f9',
};

const activeStyle = {
  border: '1px solid #000',
  backgroundColor: '#333',
};

const deActiveTextStyle = {
  color: '#999',
  fontSize: 18,
  fontWeight: '700',
};
const activeTextStyle = {
  color: '#fff',
  fontSize: 18,
  fontWeight: '700',
};

const selectBoxStyle = {
  height: 48,
  paddingLeft: 16,
  paddingRight: 16,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: 8,
  cursor: 'pointer',
};

const SHOP_LIST = ['Musinsa', 'WConcept', 'Qoo10', 'Rakuten', 'VVIC'];
const PERIOD_LIST = ['weeks', '1months', '3months'];

const ImageSearchResult = ({
  uploadImageSrc,
  onCreateImage,
  images,
  onResetImage,
  isLoading,
}) => {
  const [shop, setShop] = useState('');
  const [selectPeriod, setSelectPeriod] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [firstCategories, setFirstCategories] = useState([]);
  const [secondCategories, setSecondCategories] = useState([]);
  const [thirdCategories, setThirdCategories] = useState([]);

  const [firstCategory, setFirstCategory] = useState(null);
  const [secondCategory, setSecondCategory] = useState(null);
  const [thirdCategory, setThirdCategory] = useState(null);

  useEffect(() => {
    if (shop !== '') {
      fetchFirstCategory();
      onResetPeriod();
      onResetCategory();
      onResetImage();
    }
  }, [shop]);

  useEffect(() => {
    if (firstCategory !== null) {
      fetchSecondCategory();
    }
  }, [firstCategory]);

  useEffect(() => {
    if (secondCategory !== null) {
      fetchThirdCategory();
    }
  }, [secondCategory]);

  const fetchFirstCategory = async () => {
    const response = await getFetch(`competitor/${shop}/categoris`);

    setFirstCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };

  const fetchSecondCategory = async () => {
    const response = await getFetch(
      `competitor/${shop}/categoris?parentId=${firstCategory.key}`,
    );

    setSecondCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };

  const fetchThirdCategory = async () => {
    const response = await getFetch(
      `competitor/${shop}/categoris?parentId=${secondCategory.key}`,
    );

    setThirdCategories(
      response.map(({ id, name }) => ({ label: name, key: id })),
    );
  };

  const handleClickStartRange = (range) => {
    setStartDate(range);
  };

  const handleClickEndRange = (range) => {
    setEndDate(range);
  };

  const onResetCategory = () => {
    setFirstCategory(null);
    setSecondCategory(null);
    setThirdCategory(null);
  };

  const onResetPeriod = () => {
    setSelectPeriod('');
    setStartDate(null);
    setEndDate(null);
  };

  const handleLastWeekClick = () => {
    if (selectPeriod === 'weeks') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(1, 'weeks');
    setSelectPeriod('weeks');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleLastMonthClick = () => {
    if (selectPeriod === '1months') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(1, 'months');
    setSelectPeriod('1months');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleLastThreeMonthsClick = () => {
    if (selectPeriod === '3months') {
      onResetPeriod();
      return;
    }

    const endDate = moment();
    const startDate = moment().subtract(3, 'months');
    setSelectPeriod('3months');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleClickShop = (shopName) => {
    if (shop === shopName) {
      setShop('');
      return;
    }

    setShop(shopName);
  };

  const handleFirstClick = (key) => {
    if (key === firstCategory?.key) {
      setFirstCategory(null);
      return;
    }

    setFirstCategory(
      firstCategories.find((item) => item.key === key) || '메뉴 선택',
    );
    setSecondCategories([]);
    setThirdCategories([]);
  };

  const handleSecondClick = (key) => {
    if (key === secondCategory?.key) {
      setSecondCategory(null);
      return;
    }
    setSecondCategory(
      secondCategories.find((item) => item.key === key) || '메뉴 선택',
    );

    setThirdCategories([]);
  };

  const handleThirdClick = (key) => {
    if (key === thirdCategory?.key) {
      setThirdCategory(null);
      return;
    }
    setThirdCategory(
      thirdCategories.find((item) => item.key === key) || '메뉴 선택',
    );
  };

  const handleClickCreateButton = (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    if (shop === '') {
      alert('쇼핑몰을 선택해주세요.');
      return;
    }

    // if (
    //   firstCategory === null ||
    //   secondCategory === null ||
    //   thirdCategory === null
    // ) {
    //   alert('카테고리를 선택해주세요.');
    //   return;
    // }

    if (startDate === null || endDate === null) {
      alert('날짜를 선택해주세요.');
      return;
    }

    onCreateImage(
      shop,
      firstCategory?.key,
      secondCategory?.key,
      thirdCategory?.key,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD'),
    );
  };

  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <img
          src={uploadImageSrc}
          alt="uploadImageSrc"
          style={{ height: '100%' }}
        />
      </Col>

      <Col
        style={{
          width: '100%',
          height: 322,
          border: '1px solid #333',
          borderRadius: 16,
          marginBottom: 80,
          overflow: 'hidden',
        }}>
        {/** */}
        <Row
          style={{
            alignItems: 'center',
            height: 80,
            paddingLeft: 24,
            paddingRight: 24,
            borderBottom: '2px solid #ddd',
          }}>
          <span style={titleStyle}>한국</span>

          <div
            style={{
              ...boxStyle,
              ...(shop === SHOP_LIST[0] ? activeStyle : deActiveStyle),
              marginRight: 8,
            }}
            onClick={() => handleClickShop(SHOP_LIST[0])}>
            <span
              style={{
                ...(shop === SHOP_LIST[0]
                  ? activeTextStyle
                  : deActiveTextStyle),
              }}>
              무신사
            </span>
          </div>

          <div
            style={{
              ...boxStyle,
              ...(shop === SHOP_LIST[1] ? activeStyle : deActiveStyle),
              marginRight: 24,
            }}
            onClick={() => handleClickShop(SHOP_LIST[1])}>
            <span
              style={{
                ...(shop === SHOP_LIST[1]
                  ? activeTextStyle
                  : deActiveTextStyle),
              }}>
              W컨셉
            </span>
          </div>

          <div
            style={{
              width: 2,
              height: 20,
              marginRight: 24,
              backgroundColor: '#d9d9d9',
            }}
          />

          <span style={titleStyle}>일본</span>

          <div
            style={{
              ...boxStyle,
              ...(shop === SHOP_LIST[2] ? activeStyle : deActiveStyle),
              marginRight: 8,
            }}
            onClick={() => handleClickShop(SHOP_LIST[2])}>
            <span
              style={{
                ...(shop === SHOP_LIST[2]
                  ? activeTextStyle
                  : deActiveTextStyle),
              }}>
              큐텐
            </span>
          </div>

          <div
            style={{
              ...boxStyle,
              ...(shop === SHOP_LIST[3] ? activeStyle : deActiveStyle),
              marginRight: 24,
            }}
            onClick={() => handleClickShop(SHOP_LIST[3])}>
            <span
              style={{
                ...(shop === SHOP_LIST[3]
                  ? activeTextStyle
                  : deActiveTextStyle),
              }}>
              라쿠텐
            </span>
          </div>

          <div
            style={{
              width: 2,
              height: 20,
              marginRight: 24,
              backgroundColor: '#d9d9d9',
            }}
          />

          <span style={titleStyle}>중국</span>

          <div
            style={{
              ...boxStyle,
              ...(shop === SHOP_LIST[4] ? activeStyle : deActiveStyle),
            }}
            onClick={() => handleClickShop(SHOP_LIST[4])}>
            <span
              style={{
                ...(shop === SHOP_LIST[4]
                  ? activeTextStyle
                  : deActiveTextStyle),
              }}>
              VVIC
            </span>
          </div>
        </Row>

        {/** */}
        <Col
          style={{
            height: 144,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
            alignItems: 'center',
          }}>
          <Row style={{ justifyContent: 'space-between', marginBottom: 16 }}>
            <ImageSearchDatePicker
              selectPeriod={selectPeriod}
              handleLastWeekClick={handleLastWeekClick}
              handleLastMonthClick={handleLastMonthClick}
              handleLastThreeMonthsClick={handleLastThreeMonthsClick}
              startDate={startDate}
              endDate={endDate}
              handleClickStartRange={handleClickStartRange}
              handleClickEndRange={handleClickEndRange}
              periodList={PERIOD_LIST}
            />
          </Row>

          <ImageSearchCategory
            firstCategories={firstCategories}
            secondCategories={secondCategories}
            thirdCategories={thirdCategories}
            firstCategory={firstCategory}
            secondCategory={secondCategory}
            thirdCategory={thirdCategory}
            handleFirstClick={handleFirstClick}
            handleSecondClick={handleSecondClick}
            handleThirdClick={handleThirdClick}
          />
        </Col>

        <Row
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: 96,
            backgroundColor: '#f5f5f5',
            paddingLeft: 24,
            paddingRight: 24,
          }}>
          <Row
            style={{
              borderRadius: 8,
              border: '1px solid #000',
              backgroundColor: '#000',
              justifyContent: 'justify-content',
              alignItems: 'center',
              height: 64,
              cursor: 'pointer',
              paddingLeft: 24,
              paddingRight: 24,
              gap: 10,
            }}
            onClick={handleClickCreateButton}>
            <img
              src={searchIcon}
              alt="image"
              style={{ height: 32, width: 32 }}
            />

            <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
              {isLoading ? '검색중' : '검색하기'}
            </span>
          </Row>
        </Row>
      </Col>

      {images.length !== 0 && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
            }}
          />
          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                검색 이미지
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 16,
              }}>
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: 200,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img
                      src={image.imageUrl}
                      alt={`Image ${index}`}
                      style={{
                        height: '100%',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Col>
  );
};

export default ImageSearchResult;
