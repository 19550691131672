import React from 'react';
import { Col, Row } from 'antd';

const ImageCreateResultUp = ({ uploadImageSrc, onCreateImage, images }) => {
  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <img
          src={uploadImageSrc}
          alt="uploadImageSrc"
          style={{ height: '100%' }}
        />
      </Col>
    </Col>
  );
};

export default ImageCreateResultUp;
