import React from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';

const headerStyle = {
  display: 'flex',
  width: '100%',
  height: 80,
  backgroundColor: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
};

const Header = ({ headerIcon, headerTitle, rightIcon }) => {
  return (
    <Layout.Header style={headerStyle}>
      <Row
        style={{
          width: '100%',
          maxWidth: 1920,
          paddingRight: 24,
          paddingLeft: 24,
          display: 'f석lex',
          justifyContent: 'space-between',
        }}>
        <Row>
          <Col
            style={{
              marginRight: 8,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
            <img
              src={headerIcon}
              alt="headerLogoImage"
              style={{ width: 48, height: 48 }}
            />
          </Col>
          <Col>
            <text
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 24,
                color: '#000',
              }}>
              {headerTitle}
            </text>
          </Col>
        </Row>

        <Col
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Link to="/">
            <img
              src={rightIcon}
              alt="headerRightLogoImage"
              style={{ alignContent: 'bottom', paddingTop: 8 }}
            />
          </Link>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
