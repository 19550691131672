import React, { useRef } from 'react';
import { Col, Row } from 'antd';

import zipIcon from '../../assets/imagePortfolio/zipIcon.svg';
import csvIcon from '../../assets/imagePortfolio/csvIcon.svg';
import uploadImage from '../../assets/common/upload.svg';

const EmptyUploadFile = ({ onClickUpload, title, type }) => {
  const inputRef = useRef(null);

  return (
    <Col
      style={{
        width: 1280,
        height: 208,
        padding: 80,
        paddingTop: 50,
        borderRadius: 16,
        backgroundColor: '#f9f9f9',
        justifyContent: 'center',
      }}>
      <Row
        style={{
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <Row style={{ marginBottom: 4 }}>
          <img
            src={type === 'zip' ? zipIcon : csvIcon}
            alt={type === 'zip' ? 'zipIcon' : 'csvIcon'}
            style={{
              width: 120,
              height: 120,
              marginRight: 20,
              marginBottom: 40,
            }}
          />
          <Col>
            <div
              style={{
                marginBottom: 24,
                paddingTop: type === 'zip' ? 20 : 40,
              }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '700',
                  marginBottom: 24,
                }}>
                {title}
              </span>
            </div>
            <div>
              {type === 'zip' ? (
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: '700',
                    color: '#666',
                  }}>
                  JPG/JPEG 파일 2000장 기준 전처리 시간은 30분~40분 정도
                  소요됩니다.
                </span>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Row
            style={{
              borderRadius: 8,
              border: '1px solid #000',
              backgroundColor: '#333',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: 64,
              paddingLeft: 24,
              paddingRight: 24,
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => {
              inputRef.current?.click();
            }}>
            <img
              src={uploadImage}
              alt="uploadImage"
              style={{ width: 32, height: 32, marginRight: 10 }}
            />
            <input
              type="file"
              ref={inputRef}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'red',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'none',
              }}
              onChange={(e) => {
                onClickUpload(e);
              }}
            />
            <div>
              {type === 'zip' ? (
                <span style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}>
                  이미지 ZIP 업로드
                </span>
              ) : (
                <span style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}>
                  CSV 파일 업로드
                </span>
              )}
            </div>
          </Row>
        </Row>
      </Row>
    </Col>
  );
};

export default EmptyUploadFile;
