import React, { useState } from 'react';
import { Container, EmptyUploadImage } from '../../../components/common';
import { Col, Row } from 'antd';
import ImageCordiCreateResultUp from './ImageCordiCreateResultUp';
import { postImageFetch } from '../../../api';

const AiBasedCoordiService = () => {
  const [uploadOriginImage, setUploadOriginImage] = useState(null);
  const [uploadImageSrc, setUploadImageSrc] = useState(null);
  const [Images, setImages] = useState([]);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginImage(file);
        setUploadImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const formattedImageData = (input) => {
    const output = [];
    let number = 0;
    input.forEach((i, index) => {
      i.items.forEach((ii, index2) => {
        output.push({ image: ii.image, number: number });
        number++;
      });
    });
    return output;
  };

  const handleCreateImage = async (categoris, query_text) => {
    const formData = new FormData();
    formData.append('image', uploadOriginImage);
    formData.append('query_text', query_text);
    formData.append('topk', 10);
    formData.append('categoris', categoris.toString());
    const response = await postImageFetch('ml/cordi', formData);
    const res = formattedImageData(response);
    setImages(res ?? []);
  };

  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            AI 기반 코디
          </span>
        </Col>
      </Row>
      {uploadImageSrc === null ? (
        <EmptyUploadImage onClickUpload={handleChangeImage} />
      ) : (
        <ImageCordiCreateResultUp
          uploadImageSrc={uploadImageSrc}
          onCreateImage={handleCreateImage}
          images={Images}
        />
      )}
    </Container>
  );
};

export default AiBasedCoordiService;
