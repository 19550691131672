import React, { useRef } from 'react';
import { Col, Row } from 'antd';

import emptyImage from '../../assets/common/emptyImage.svg';
import uploadImage from '../../assets/common/upload.svg';

const EmptyUploadImage = ({
  onClickUpload,
  title = '이미지 업로드를 통해 자유롭게 디자인을 생성하시고,',
}) => {
  const inputRef = useRef(null);

  return (
    <Col
      style={{
        width: 1280,
        height: 480,
        padding: 80,
        borderRadius: 16,
        backgroundColor: '#f9f9f9',
        justifyContent: 'center',
      }}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}>
        <Row>
          <img
            src={emptyImage}
            alt="emptyImage"
            style={{ width: 120, height: 120, marginRight: 20 }}
          />
          <Col>
            <div style={{ marginBottom: 24 }}>
              <span
                style={{ fontSize: 24, fontWeight: '700', marginBottom: 24 }}>
                {title}
              </span>
              <br />
              <span
                style={{ fontSize: 24, fontWeight: '700', marginBottom: 24 }}>
                제품생산을 의뢰하세요
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: 18,
                  fontWeight: '700',
                  color: '#666',
                }}>
                JPG/JPEG 파일 1개만 가능
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Row
            style={{
              borderRadius: 8,
              border: '1px solid #000',
              backgroundColor: '#333',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: 64,
              paddingLeft: 24,
              paddingRight: 24,
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => {
              inputRef.current?.click();
            }}>
            <img
              src={uploadImage}
              alt="uploadImage"
              style={{ width: 32, height: 32, marginRight: 10 }}
            />
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'red',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'none',
              }}
              onChange={(e) => {
                onClickUpload(e);
              }}
            />
            <div>
              <span style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}>
                이미지 업로드
              </span>
            </div>
          </Row>
        </Row>
      </Row>
    </Col>
  );
};

export default EmptyUploadImage;
