import React, { useEffect, useState } from 'react';
import { postImageFetch } from '../../../api';
import { Pie } from '@ant-design/plots';
import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { Container } from '../../../components/common';
import EmptyUploadFile from '../../../components/common/EmptyUploadFIle';
import ImagePortfolioResult from './ImagePortfolioResult';
import buttonIcon from '../../../assets/common/buttonIcon2.svg';
import buttonIconLeft from '../../../assets/common/buttonIconLeft.svg';
import buttonIconRight from '../../../assets/common/buttonIconRight.svg';
const ImagePortfolioService = () => {
  const [uploadZIPfile, setUploadZIPfile] = useState(null);
  const [uploadZIPfileName, setUploadZIPfileName] = useState(null);
  const [uploadZIPfileSrc, setUploadZIPfileSrc] = useState(null);

  const [uploadCSVfile, setUploadCSVfile] = useState(null);
  const [uploadCSVfileName, setUploadCSVfileName] = useState(null);
  const [uploadCSVfileSrc, setUploadCSVfileSrc] = useState(null);

  const [callResult, setCallResult] = useState([]);

  const [selectedCurrent, setSelectedCurrent] = useState(0);
  const [currentOption, setCurrentOption] = useState(2);
  const [totalCount, setTotalCount] = useState(0);

  const [brandPieData, setBrandPieData] = useState([{ type: '', value: 100 }]);
  const [pricePieData, setPricePieData] = useState([{ type: '', value: 100 }]);
  const [catPieData, setCatPieData] = useState([{ type: '', value: 100 }]);

  useEffect(() => {}, []);

  const handleChangeZIPfile = (e) => {
    const file = e.target.files[0];
    setUploadZIPfileName(e.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadZIPfile(file);
        setUploadZIPfileSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleChangeCSVfile = (e) => {
    const file = e.target.files[0];
    setUploadCSVfileName(e.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadCSVfile(file);
        setUploadCSVfileSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key={1} onClick={() => handleMenuClick(1)}>
        1
      </Menu.Item>
      <Menu.Item key={2} onClick={() => handleMenuClick(2)}>
        2
      </Menu.Item>
      <Menu.Item key={3} onClick={() => handleMenuClick(3)}>
        3
      </Menu.Item>
      <Menu.Item key={4} onClick={() => handleMenuClick(4)}>
        4
      </Menu.Item>
      <Menu.Item key={5} onClick={() => handleMenuClick(5)}>
        5
      </Menu.Item>
    </Menu>
  );

  const brandPieConfig = {
    style: { width: 400, height: 400 },
    appendPadding: 10,
    data: brandPieData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const pricePieConfig = {
    style: { width: 400, height: 400 },
    appendPadding: 10,
    data: pricePieData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const catPieConfig = {
    style: { width: 400, height: 400 },
    appendPadding: 10,
    data: catPieData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const handleMenuClick = (key) => {
    setCurrentOption(key);
  };

  const handleCreateImage = async () => {
    const formData = new FormData();
    formData.append('k', currentOption);
    formData.append('csv_file', uploadCSVfile);
    formData.append('image_zip', uploadZIPfile);

    const res = await postImageFetch('ml/image-portfolio', formData);
    console.log('res > ', res);
    setCallResult(res);
    setTotalCount(findTotalSize(res));
  };

  const findTotalSize = (input) => {
    let cnt = 0;
    input.forEach((i) => {
      cnt += i.count;
    });
    return cnt;
  };

  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            이미지 Portfolio
          </span>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 12,
              color: '#666666',
            }}>
            이미지 특징을 분석하여 분류하며, 화이트 스페이스 등을 확인 할 수
            있습니다.
          </span>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}></Row>
      <Col
        style={{
          width: 1280,
          height: 154,
          padding: 40,
          marginBottom: 20,
          borderRadius: 16,
          backgroundColor: '#f9f9f9',
        }}>
        <Row
          style={{
            height: '100%',
          }}>
          <Col>
            <div style={{ marginBottom: 24 }}>
              <span
                style={{
                  fontSize: 18,
                  fontWeight: '700',
                  marginBottom: 24,
                  color: '#333333',
                }}>
                클러스터링을 위해서는 이미지 파일과 이미지와 매칭되는 메타
                데이터(카테고리, 브랜드, 가격)가 필요합니다.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontSize: 12,
                  fontWeight: '800',
                  marginBottom: 24,
                  color: '#666666',
                }}>
                1. 이미지는 JPG, JPEG 파일만 가능 합니다.
              </span>
              <br />
              <span
                style={{
                  fontSize: 12,
                  fontWeight: '800',
                  marginBottom: 24,
                  color: '#666666',
                }}>
                2. 메타 데이터는 샘플 엑셀 파일을 다운로드 받아서 작성 하셔야
                합니다.
              </span>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: '800',
                  marginBottom: 24,
                  color: '#666666',
                }}>
                <a href="https://cdn.out.araas.ai/portfolio/sample.csv">
                  샘플 다운로드
                </a>
              </span>
              <br />
              <span
                style={{
                  fontSize: 12,
                  fontWeight: '800',
                  marginBottom: 24,
                  color: '#666666',
                }}>
                3. 데이터가 틀릴 경우, 클러스터링의 정확도가 떨어지니 정확한
                데이터를 입력해 주세요.
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}></Row>
      {uploadZIPfileSrc === null ? (
        <EmptyUploadFile
          onClickUpload={handleChangeZIPfile}
          title={'클러스팅을 진행할 이미지 ZIP파일을 업로드 해주세요.'}
          type={'zip'}
        />
      ) : (
        <ImagePortfolioResult type={'zip'} fileName={uploadZIPfileName} />
      )}
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
          marginTop: 20,
        }}></Row>
      {uploadCSVfileSrc === null ? (
        <EmptyUploadFile
          onClickUpload={handleChangeCSVfile}
          title={'클러스팅을 진행할 이미지 CSV파일을 업로드 해주세요.'}
        />
      ) : (
        <ImagePortfolioResult type={'csv'} fileName={uploadCSVfileName} />
      )}
      <Col
        style={{
          width: 1280,
          height: 112,

          marginTop: 40,
          marginBottom: 20,
          borderRadius: 16,
          backgroundColor: '#f9f9f9',
        }}>
        <Row
          style={{
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <Col style={{ padding: 36, width: 700 }}>
            <span style={{ fontSize: 24, fontWeight: '700' }}>
              불러온 이미지{' '}
            </span>
            <span style={{ fontSize: 24, fontWeight: '700', color: '#27E9D1' }}>
              총 {totalCount}개
            </span>
          </Col>
          <Col style={{ paddingTop: 45 }}>
            <span style={{ fontSize: 18, fontWeight: '700' }}>군집 개수</span>
          </Col>
          <Col style={{ padding: 36 }}>
            <Dropdown overlay={menu}>
              <div
                onClick={(e) => e.preventDefault()}
                style={{
                  width: 120,
                  height: 40,
                  paddingLeft: 16,
                  paddingRight: 8,
                  borderRadius: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #DDD',
                  backgroundColor: '#fff',
                }}>
                <span style={{ fontSize: 15, fontWeight: '700' }}>
                  {currentOption}
                </span>
                <img
                  src={buttonIcon}
                  alt="image"
                  style={{ height: 32, width: 32 }}
                />
              </div>
            </Dropdown>
          </Col>
          <Col style={{ padding: 24 }}>
            <Button
              onClick={handleCreateImage}
              style={{
                backgroundColor: '#1DD9C2',
                width: 240,
                height: 64,
                fontSize: 24,
                fontWeight: '700',
                color: '#fff',
              }}>
              생성하기
            </Button>
          </Col>
        </Row>
      </Col>
      <Col style={{ paddingTop: 40, paddingBottom: 40 }}>
        <Row>
          <Col>
            <img src={buttonIconLeft} />
          </Col>
          <Col style={{ width: 1184 }}>
            <Row>
              {callResult.map((i, index) => {
                return (
                  <Col style={{ marginRight: 10 }}>
                    <Button
                      onClick={() => {
                        setSelectedCurrent(index);

                        const pricekeys = Object.keys(
                          callResult[index].priceDist.data,
                        );
                        const priceData = [];
                        pricekeys.forEach((i) => {
                          priceData.push({
                            type: i,
                            value: callResult[index].priceDist.data[i],
                          });
                        });
                        setPricePieData(priceData);

                        const brandkeys = Object.keys(
                          callResult[index].brandDist.data,
                        );
                        const brandData = [];
                        brandkeys.forEach((i) => {
                          brandData.push({
                            type: i,
                            value: callResult[index].brandDist.data[i],
                          });
                        });
                        setBrandPieData(brandData);

                        const catkeys = Object.keys(
                          callResult[index].catDist.data,
                        );
                        const catData = [];
                        catkeys.forEach((i) => {
                          catData.push({
                            type: i,
                            value: callResult[index].catDist.data[i],
                          });
                        });
                        setCatPieData(catData);
                      }}
                      style={{ width: 159, height: 48, fontSize: 18 }}>
                      군집{index + 1} {i.count}개
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col
            style={{
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              display: 'flex',
            }}>
            <img src={buttonIconRight} />
          </Col>
        </Row>
      </Col>
      {callResult.length === 0 ? null : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            gap: 16,
          }}>
          {callResult[selectedCurrent].data.map((image, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 200,
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                }}>
                <img
                  src={image.imgUrl}
                  alt={`Image ${index}`}
                  style={{
                    height: '100%',
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      {callResult.length > 0 ? (
        <Col>
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 50,
            }}>
            <Col>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: 24, fontWeight: 800 }}>
                  가격대 분포
                </span>
              </Row>
              <Row style={{ width: 400, height: 400 }}>
                <Pie {...pricePieConfig} />
              </Row>
            </Col>
            <Col>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: 24, fontWeight: 800 }}>
                  브랜드 분포
                </span>
              </Row>
              <Row style={{ width: 400, height: 400 }}>
                <Pie {...brandPieConfig} />
              </Row>
            </Col>
            <Col>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: 24, fontWeight: 800 }}>
                  카테고리 분포
                </span>
              </Row>
              <Row style={{ width: 400, height: 400 }}>
                <Pie {...catPieConfig} />
              </Row>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Container>
  );
};

export default ImagePortfolioService;
