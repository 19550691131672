import React, { useState, useEffect } from 'react';
import { Col, Row, Dropdown, Menu } from 'antd';
import { EmptyUploadImage, Container } from '../../../components/common';
import { getFetch, postImageFetch } from '../../../api';
import ImageCreateResult from './ImageCreateResult';

import buttonIcon from '../../../assets/common/buttonIcon.svg';

const ImageCreateService = () => {
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);
  const [uploadOriginImage, setUploadOriginImage] = useState(null);
  const [uploadImageSrc, setUploadImageSrc] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const response = await getFetch('ml/image2image/categories');

    setOptions(
      response.map(({ view, category }) => ({ label: view, key: category })),
    );
  };

  const handleMenuClick = (key) => {
    if (key === currentOption?.key) {
      setCurrentOption(null);
      return;
    }
    setCurrentOption(options.find((item) => item.key === key) || '메뉴 선택');
  };

  const menu = (
    <Menu>
      {options.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleMenuClick(item.key)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleChangeImage = (e) => {
    console.log(e);
    const file = e.target.files[0];
    console.log(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginImage(file);
        setUploadImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleCreateImage = async () => {
    if (currentOption === null) {
      alert('카테고리를 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('category', currentOption.key);
    formData.append('image', uploadOriginImage);

    const response = await postImageFetch('ml/image2image', formData);

    setImages(response ?? []);
  };

  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 40,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            이미지 기반 제품 디자인 생성
          </span>
        </Col>

        <Col>
          <Dropdown overlay={menu}>
            <div
              onClick={(e) => e.preventDefault()}
              style={{
                width: 200,
                height: 48,
                paddingLeft: 16,
                paddingRight: 8,
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #DDD',
                backgroundColor: '#fff',
              }}>
              <span style={{ fontSize: 18, fontWeight: '700' }}>
                {currentOption === null ? '카테고리 선택' : currentOption.label}
              </span>

              <img
                src={buttonIcon}
                alt="image"
                style={{ height: 32, width: 32 }}
              />
            </div>
          </Dropdown>
        </Col>
      </Row>

      {uploadImageSrc === null ? (
        <EmptyUploadImage onClickUpload={handleChangeImage} />
      ) : (
        <ImageCreateResult
          uploadImageSrc={uploadImageSrc}
          onCreateImage={handleCreateImage}
          images={images}
        />
      )}
    </Container>
  );
};

export default ImageCreateService;
