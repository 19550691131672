import React from 'react';
import { Col, Row } from 'antd';
import zipIcon from '../../../assets/imagePortfolio/zipIcon.svg';
import csvIcon from '../../../assets/imagePortfolio/csvIcon.svg';

const ImagePortfolioResult = ({ type, fileName }) => {
  return (
    <Col>
      <Col
        style={{
          width: 1280,
          height: 208,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          borderRadius: 16,
          overflow: 'hidden',
          padding: 48,
          marginBottom: 20,
        }}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            width: '100%',
            marginBottom: 20,
          }}>
          <Col>
            <img
              src={type === 'zip' ? zipIcon : csvIcon}
              alt={type === 'zip' ? 'uploadZIP' : 'uploadCSV'}
              style={{ width: 48, height: 48 }}
            />
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <span style={{ fontSize: 24, fontWeight: 700, color: '#000' }}>
              {fileName}
            </span>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default ImagePortfolioResult;
