import React from 'react';
import { Card, Space, Row } from 'antd';

export const DashBoardCard = ({ iconImage, title, content, image }) => (
  <div style={{ background: '#333333', padding: '20px 0' }}>
    <Space
      direction="vertical"
      size="100"
      style={{
        display: 'flex',
        backgroundColor: '#FFF',
        width: 400,
        height: 400,
      }}>
      <Row style={{ height: 200 }}>
        <Card style={{ borderRadius: 0 }}>
          <Row>
            <img src={iconImage} style={{ alignContent: 'bottom' }} />
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <text style={{ fontSize: 24, fontWeight: 'bold' }}>{title}</text>
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <text style={{ fontSize: 16, color: '#666666', textAlign: 'left' }}>
              {content}
            </text>
          </Row>
        </Card>
      </Row>
      <Row style={{ height: 200 }}>
        <img
          src={image}
          style={{ alignContent: 'bottom', padding: 0, display: 'flex' }}
        />
      </Row>
    </Space>
  </div>
);
