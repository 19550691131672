import React, { useEffect, useState } from 'react';
import { postImageFetch } from '../../../api';
import { Col, Row } from 'antd';
import { Container, EmptyUploadImage } from '../../../components/common';
import ImageCreateResultUp from './ImageCreateResultUp';
import ImageCreateResultDown from './ImageCreateResultDown';

const AiBasedStyleService = () => {
  const [uploadOriginBaseImage, setUploadOriginBaseImage] = useState(null);
  const [uploadBaseImageSrc, setUploadBaseImageSrc] = useState(null);

  const [uploadOriginStyleImage, setUploadOriginStyleImage] = useState(null);
  const [uploadStyleImageSrc, setUploadStyleImageSrc] = useState(null);

  const [baseImages, setBaseImages] = useState([]);

  useEffect(() => {}, []);

  const handleChangeBaseImage = (e) => {
    console.log('1> ', e);
    const file = e.target.files[0];
    console.log('1> ', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginBaseImage(file);
        setUploadBaseImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleChangeStyleImage = (e) => {
    console.log('2> ', e);

    const file = e.target.files[0];
    console.log('2> ', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginStyleImage(file);
        setUploadStyleImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleCreateImage = async () => {
    const formData = new FormData();
    formData.append('content', uploadOriginBaseImage);
    formData.append('style', uploadOriginStyleImage);
    await postImageFetch('ml/styletransfer', formData).then((r) =>
      setBaseImages([r]),
    );
  };

  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            AI 기반 스타일
          </span>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 40,
        }}>
        <Col>
          <span
            style={{
              fontSize: 12,
              color: '#666666',
            }}>
            특정 이미지에 특정 Style을 입혀 새로운 디자인을 생성합니다.
          </span>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            기준 이미지
          </span>
        </Col>
      </Row>
      {uploadBaseImageSrc === null ? (
        <EmptyUploadImage onClickUpload={handleChangeBaseImage} />
      ) : (
        <ImageCreateResultUp
          uploadImageSrc={uploadBaseImageSrc}
          onCreateImage={handleCreateImage}
          images={baseImages}
        />
      )}
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 20,
          marginTop: 20,
        }}>
        <Col>
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
            }}>
            스타일 이미지
          </span>
        </Col>
      </Row>
      {uploadStyleImageSrc === null ? (
        <EmptyUploadImage onClickUpload={handleChangeStyleImage} />
      ) : (
        <ImageCreateResultDown
          uploadImageSrc={uploadStyleImageSrc}
          onCreateImage={handleCreateImage}
          images={baseImages}
        />
      )}
    </Container>
  );
};

export default AiBasedStyleService;
