import React from 'react';
import { Col } from 'antd';

export const Container = ({ children }) => {
  return (
    <Col
      style={{
        width: '100%',
        maxWidth: 1920,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: 80,
      }}>
      <Col style={{ width: 1280 }}>{children}</Col>
    </Col>
  );
};

export default Container;
