import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Dashboard } from './pages/dashboard';
import { Product } from './pages/product';
import { Fashion } from './pages/fashion';
import { Design } from './pages/design';
import { SimilarProduct } from './pages/similarProduct';
import { Ai } from './pages/ai';
import { ImagePortfolio } from './pages/imagePortfolio';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/feshion" element={<Fashion />} />
        <Route path="/product" element={<Product />} />
        <Route path="/design" element={<Design />} />
        <Route path="/portfolio" element={<ImagePortfolio />} />
        <Route path="/similar-product" element={<SimilarProduct />} />
        <Route path="/ai" element={<Ai />} />
      </Routes>
    </Router>
  );
};

export default App;
