import React from 'react';
import { Row, DatePicker } from 'antd';
import dateIcon from '../../../assets/design/date.svg';

const deActiveStyle = {
  border: '1px solid #DDD',
  backgroundColor: '#f9f9f9',
};

const activeStyle = {
  border: '1px solid #000',
  backgroundColor: '#333',
};

const deActiveTextStyle = {
  color: '#999',
  fontSize: 18,
  fontWeight: '700',
};
const activeTextStyle = {
  color: '#fff',
  fontSize: 18,
  fontWeight: '700',
};

const selectBoxStyle = {
  height: 48,
  paddingLeft: 16,
  paddingRight: 16,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: 8,
  cursor: 'pointer',
};

const ImageSearchDatePicker = ({
  selectPeriod,
  periodList,
  handleLastWeekClick,
  handleLastMonthClick,
  handleLastThreeMonthsClick,
  startDate,
  endDate,
  handleClickStartRange,
  handleClickEndRange,
}) => {
  return (
    <>
      <Row style={{ gap: 8 }}>
        <div
          style={{
            ...selectBoxStyle,
            ...(selectPeriod === periodList[0] ? activeStyle : deActiveStyle),
          }}
          onClick={handleLastWeekClick}>
          <span
            style={{
              ...(selectPeriod === periodList[0]
                ? activeTextStyle
                : deActiveTextStyle),
            }}>
            최근 1주일
          </span>
        </div>

        <div
          style={{
            ...selectBoxStyle,
            ...(selectPeriod === periodList[1] ? activeStyle : deActiveStyle),
          }}
          onClick={handleLastMonthClick}>
          <span
            style={{
              ...(selectPeriod === periodList[1]
                ? activeTextStyle
                : deActiveTextStyle),
            }}>
            최근 1개월
          </span>
        </div>

        <div
          style={{
            ...selectBoxStyle,
            ...(selectPeriod === periodList[2] ? activeStyle : deActiveStyle),
          }}
          onClick={handleLastThreeMonthsClick}>
          <span
            style={{
              ...(selectPeriod === periodList[2]
                ? activeTextStyle
                : deActiveTextStyle),
            }}>
            최근 3개월
          </span>
        </div>
      </Row>

      <Row style={{ gap: 8, alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            paddingLeft: 16,
            height: 48,
            paddingRight: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}></div>

        <div
          style={{
            display: 'flex',
            paddingLeft: 16,
            height: 48,
            paddingRight: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}></div>
      </Row>
    </>
  );
};

export default ImageSearchDatePicker;
