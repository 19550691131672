import React from 'react';
import { Col, Layout, Row } from 'antd';

const tabStyle = {
  display: 'flex',
  width: '100%',
  height: 54,
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid #ddd',
  borderTop: '1px solid #ddd',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: 80,
  paddingLeft: 80,
};

const Tab = ({ currentTab, tabs, onClickTab, activeColor }) => {
  return (
    <Layout.Header style={tabStyle}>
      <Row
        style={{
          width: '100%',
          maxWidth: 1920,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
        <Row style={{ gap: 40 }}>
          {tabs.map(({ tab, key }) => (
            <Col
              key={tab}
              style={{
                cursor: 'pointer',
                fontSize: 18,
                fontWeight: currentTab.key === key ? '800' : '700',
                color: currentTab.key === key ? activeColor : '#999',
              }}
              onClick={() => onClickTab({ tab, key })}>
              {tab}
            </Col>
          ))}
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default Tab;
