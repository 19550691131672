import React, { useState } from 'react';
import { Col } from 'antd';
import { EmptyUploadImage, Container } from '../../../components/common';
import { postImageFetch } from '../../../api';
import ImageSearchResult from './ImageSearchResult';

const ImageSearch = () => {
  const [uploadOriginImage, setUploadOriginImage] = useState(null);
  const [uploadImageSrc, setUploadImageSrc] = useState(null);
  const [images, setImages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginImage(file);
        setUploadImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleCreateImage = async (
    shop,
    category1,
    category2,
    category3,
    start,
    end,
  ) => {
    const formData = new FormData();
    formData.append('category_1', category1);
    formData.append('category_2', category2);
    formData.append('category_3', category3);
    formData.append('start_date', start);
    formData.append('end_date', end);
    formData.append('image', uploadOriginImage);

    setLoading(true);

    const response = await postImageFetch(
      `search/images?site=${shop}`,
      formData,
    );

    setImages(response ?? []);
    setLoading(false);
  };

  const onResetImage = () => {
    setImages([]);
  };

  return (
    <Container>
      <Col style={{ marginBottom: 40 }}>
        <span style={{ fontSize: 24, fontWeight: '800' }}>이미지 검색</span>
      </Col>

      {uploadImageSrc === null ? (
        <EmptyUploadImage
          title="이미지 업로드를 통해 자유롭게 디자인을 검색하시고,"
          onClickUpload={handleChangeImage}
        />
      ) : (
        <ImageSearchResult
          uploadImageSrc={uploadImageSrc}
          onCreateImage={handleCreateImage}
          images={images}
          onResetImage={onResetImage}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

export default ImageSearch;
