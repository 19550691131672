import React, { useState } from 'react';
import { Layout, Space, Col, Row, Menu, theme } from 'antd';
import logoImage from '../../assets/dashboard/fashionTrendIcon.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';

import naverFashionHeader from '../../assets/fashion/naverFashionHeader.svg';
import googleFashionHeader from '../../assets/fashion/googleFashionHeader.svg';
import baiduFashionHeader from '../../assets/fashion/baiduFashionHeader.svg';

import { NaverTrendRanking } from './NaverTrendRanking';
import { GoogleTrendRanking } from './GoogleTrendRanking';
import { BaiduTrendRanking } from './BaiduTrendRanking';
import { NaverTrendSearching } from './NaverTrendSearching';
import { GoogleTrendSearching } from './GoogleTrendSearching';
import { BaiduTrendSearching } from './BaiduTrendSearching';
import { Link } from 'react-router-dom';

const { Header, Footer, Sider, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#fff',
  height: 380,
  paddingInline: 0,
  lineHeight: '64px',
  backgroundColor: '#FFFFFF',
};

const contentStyle = {
  textAlign: 'center',
  lineHeight: '120px',
  backgroundColor: '#FFFFFF',
};

export const Fashion = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [current, setCurrent] = useState('naver-trend-ranking');

  const onClick = (e) => {
    console.log(e);
    setCurrent(e.key);
  };

  return (
    <div className="App">
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        <Header style={headerStyle}>
          <Row
            style={{
              width: '100%',
              maxWidth: 1920,
              paddingRight: 24,
              paddingLeft: 24,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Row>
              <Col
                style={{
                  marginRight: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <img
                  src={logoImage}
                  alt="headerLogoImage"
                  style={{ width: 48, height: 48 }}
                />
              </Col>
              <Col>
                <text
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 24,
                    color: '#000',
                  }}>
                  Fashion Trend 분석
                </text>
              </Col>
            </Row>
            <Col
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
              <Link to="/">
                <img
                  src={commonHeaderLogo}
                  alt="headerRightLogoImage"
                  style={{ alignContent: 'bottom', paddingTop: 8 }}
                />
              </Link>
            </Col>
          </Row>
          <Row
            style={{ backgroundColor: '#F9F9F9', height: 65, paddingLeft: 60 }}>
            <Menu
              style={{ color: '#999999', width: 2000 }}
              theme="#999999"
              mode="horizontal"
              onClick={onClick}
              selectedKeys={[current]}
              items={[
                { label: 'Naver Trend 순위', key: 'naver-trend-ranking' },
                {
                  label: 'Naver Trend 검색량 분석',
                  key: 'naver-trend-analysis',
                },
                { label: 'Google Japan 순위', key: 'google-trend-ranking' },
                {
                  label: 'Google Japan 검색량 분석',
                  key: 'google-trend-analysis',
                },
                { label: 'Baidu 순위', key: 'baidu-trend-ranking' },
                { label: 'Baidu 검색량 분석', key: 'baidu-trend-analysis' },
              ]}
            />
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {current === 'naver-trend-ranking' ||
            current === 'naver-trend-analysis' ? (
              <img
                src={naverFashionHeader}
                alt="fashionHeader"
                style={{ alignContent: 'center' }}
              />
            ) : current === 'google-trend-analysis' ||
              current === 'google-trend-ranking' ? (
              <img
                src={googleFashionHeader}
                alt="fashionHeader"
                style={{ alignContent: 'center' }}
              />
            ) : current === 'baidu-trend-ranking' || 'baidu-trend-analysis' ? (
              <img
                src={baiduFashionHeader}
                alt="fashionHeader"
                style={{ alignContent: 'center' }}
              />
            ) : null}
          </Row>
        </Header>
        <Content style={contentStyle}>
          {current === 'naver-trend-ranking' ? (
            <NaverTrendRanking />
          ) : current === 'google-trend-ranking' ? (
            <GoogleTrendRanking />
          ) : current === 'baidu-trend-ranking' ? (
            <BaiduTrendRanking />
          ) : current === 'naver-trend-analysis' ? (
            <NaverTrendSearching />
          ) : current === 'google-trend-analysis' ? (
            <GoogleTrendSearching />
          ) : current === 'baidu-trend-analysis' ? (
            <BaiduTrendSearching />
          ) : null}
        </Content>
      </Space>
    </div>
  );
};
