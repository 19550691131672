import React, { useState } from 'react';
import { Col } from 'antd';
import { EmptyUploadImage, Container } from '../../../components/common';
import ImageSearchResult from './ImageSearchResult';
import { postImageFetch } from '../../../api';

const ImageSearch = () => {
  const [uploadOriginImage, setUploadOriginImage] = useState(null);
  const [uploadImageSrc, setUploadImageSrc] = useState(null);
  const [images, setImages] = useState([]);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onload = () => {
        setUploadOriginImage(file);
        setUploadImageSrc(reader.result || null); // 파일의 컨텐츠
        resolve();
      };
    });
  };

  const handleCreateImage = async () => {
    const formData = new FormData();

    formData.append('image', uploadOriginImage);

    const response = await postImageFetch(
      'ml/similarity/image2image',
      formData,
    );
    console.log('response', response);
    setImages(response ?? []);
  };

  return (
    <Container>
      <Col style={{ marginBottom: 40 }}>
        <span style={{ fontSize: 24, fontWeight: '800' }}>
          이미지 기반 유사 상품 검색
        </span>
      </Col>

      {uploadImageSrc === null ? (
        <EmptyUploadImage onClickUpload={handleChangeImage} />
      ) : (
        <ImageSearchResult
          uploadImageSrc={uploadImageSrc}
          onCreateImage={handleCreateImage}
          images={images}
        />
      )}
    </Container>
  );
};

export default ImageSearch;
