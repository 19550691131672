import React, { useState } from 'react';
import { Header } from '../../components/header';
import { Tab } from '../../components/tab';
import {
  ImageCreateService,
  ImageSearch,
  KeywordCreateService,
} from './components';

import designHeaderLogo from '../../assets/design/designLogo.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';

const DEFAULT_TAB = { tab: '이미지기반 제품 디자인 생성 서비스', key: 'step1' };

const TAB = [
  DEFAULT_TAB,
  {
    tab: '키워드 기반 제품 디자인 생성 서비스',
    key: 'step2',
  },
  {
    tab: '이미지 검색',
    key: 'step3',
  },
];

export const Design = () => {
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);

  const handleClickTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <Header
        headerIcon={designHeaderLogo}
        headerTitle="Design 추천"
        rightIcon={commonHeaderLogo}
      />
      <Tab
        tabs={TAB}
        currentTab={currentTab}
        onClickTab={handleClickTab}
        activeColor="#FF5C00"
      />
      <RenderBody step={currentTab.key} />
    </div>
  );
};

function RenderBody({ step }) {
  switch (step) {
    case 'step1':
      return <ImageCreateService />;
    case 'step2':
      return <KeywordCreateService />;
    case 'step3':
      return <ImageSearch />;
    default:
      return null;
  }
}
