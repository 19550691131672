import React from 'react';
import { Header } from '../../components/header';
import imageLogo from '../../assets/imagePortfolio/imageLogo.svg';
import commonHeaderLogo from '../../assets/common/headerFrameIcon.svg';
import ImagePortfolioService from './components/ImagePortfolioService';

export const ImagePortfolio = () => {
  return (
    <div>
      <Header
        headerIcon={imageLogo}
        headerTitle="이미지 Portfolio"
        rightIcon={commonHeaderLogo}
      />
      <ImagePortfolioService />
    </div>
  );
};
