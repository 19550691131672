import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Container, KeywordSearchInput } from '../../../components/common';
import { postFetch, getFetch } from '../../../api';

const KeywordCreateService = () => {
  const [keyword, setKeyword] = useState('');
  const [imageList, setImageList] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleClickCreate = async (e) => {
    e.preventDefault();

    if (keyword === '') {
      alert('키워드를 입력해주세요.');
      return;
    }

    if (loading) {
      return;
    }

    const response = await postFetch('ml/text2image', { prompt: keyword });

    setLoading(true);

    while (true) {
      try {
        const getImage = await getFetch(`ml/text2image/${response?.task_id}`);
        console.log('getImage', getImage);
        if (getImage.stats === 'SUCCESS') {
          setImageList(getImage?.images ?? null);
          setLoading(false);
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // 1초 대기 후 다시 시도
        }
      } catch (error) {
        console.error('API 요청 오류:', error);
      }
    }
  };

  return (
    <Container>
      <KeywordSearchInput
        keyword={keyword}
        onChangeText={handleChangeKeyword}
      />

      <Col style={{ marginTop: 20, marginBottom: 20 }}>
        <Col
          style={{
            width: '100%',
            backgroundColor: '#f9f9f9',
            borderRadius: 16,
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 40,
            paddingRight: 24,
          }}>
          <Row
            style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Col>
              <Col style={{ marginBottom: 10 }}>
                <span style={{ fontSize: 18, fontWeight: '700' }}>
                  상상하는 이미지를 텍스트로 풀어서 적으면 참고하여 이미지를
                  생성합니다.
                </span>
              </Col>
              <Col>
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: '#666',
                    lineHeight: '24px',
                  }}>
                  1. 영어로 입력해야 결과가 좋게 나옵니다.
                </span>
              </Col>
              <Col>
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: '#666',
                    lineHeight: '24px',
                  }}>
                  2. 입력 텍스트에 따라 생성 시간이 많이 소요될 수
                  있습니다.(2분~4분)
                </span>
              </Col>
              <Col>
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: '#666',
                    lineHeight: '24px',
                  }}>
                  3. 기본 메인 이미지는 2개가 생성되며, 변경 이미지는 최대
                  8개까지 생성 가능합니다.
                </span>
              </Col>
            </Col>

            <Col
              style={{
                borderRadius: 8,
                border: '1px solid #ff5c00',
                backgroundColor: '#ff5c00',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 240,
                height: 64,
              }}
              onClick={handleClickCreate}>
              <span style={{ color: '#fff', fontSize: 24, fontWeight: '700' }}>
                {loading ? '생성중' : '생성하기'}
              </span>
            </Col>
          </Row>
        </Col>
      </Col>

      {imageList !== null && (
        <>
          <div
            style={{
              width: 1280,
              height: 1,
              backgroundColor: '#D9D9D9 ',
              marginBottom: 80,
              marginTop: 60,
            }}
          />

          <Col style={{ marginBottom: 40 }}>
            <div style={{ marginBottom: 40 }}>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: '800',
                }}>
                생성 이미지
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 16,
                marginBottom: 80,
              }}>
              {imageList.map((item, index) => {
                return (
                  <div key={index} style={{ width: 308, height: 308 }}>
                    <img src={item} key={index} style={{ width: '100%' }} />
                  </div>
                );
              })}
            </div>
          </Col>
        </>
      )}
    </Container>
  );
};

export default KeywordCreateService;
